import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Autocomplete from "@mui/lab/Autocomplete";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useForm } from "../../hooks/useForm";
import Controls from "../../widgets/uncontrolled";
import Snackbar from "../../widgets/Snackbar";
import { communeService, userService } from "../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { IRole } from "../../interfaces";
import SignatureCanvas from "react-signature-canvas";
import Module from "app/partials/content/Module";
import ReactSignatureCanvas from "react-signature-canvas";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import { DatePicker, Space } from "antd";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { AutocompletePrediction } from "app/partials/content/TimeTrack";
import { ICommune } from "../../services/comunne.service";

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

export type FormStateValues = {
  name: string;
  firstname: string;
  lastname: string;
  birthdate: string;
  gender: string;
  email: string;
  private_email: string;
  ssn: string;
  sessions: string;
  avatar: string;
  only_self: string;
  phone: string;
  city: string;
  address: string;
  currency: string;
  budget: number;
  work_hours: number;
  work_hours_type: string;
  address_json: AutocompletePrediction | string;
  zipcode: string;
  languages: Array<string>;
  role: string;
  assigned_communes?: string[];
  commune?: string;
  password: string;
  payment_registration_number: string;
  payment_account_number: string;
  signature: string;
  active: number;
};

const initValues = {
  name: "",
  firstname: "",
  lastname: "",
  birthdate: "",
  gender: "",
  email: "",
  private_email: "",
  ssn: "",
  phone: "",
  place: "",
  work_hours: 0,
  work_hours_type: "",
  currency: "",
  budget: 0,
  address: "",
  address_json: "",
  zipcode: "",
  sessions: "",
  only_self: "",
  avatar: "",
  password: "",
  payment_registration_number: "",
  payment_account_number: "",
  signature: "",
  city: "",
  role: "",
  languages: [],
  active: 1,
} as FormStateValues;

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    backgroundColor: "#E0F2FF",
    boxShadow: "none",
    pr: 3,
  },
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  signature: {
    padding: "16px 0 0 16px",
    "& .kt-portlet": {
      "& .kt-portlet__head": {
        display: "none!important",
      },
      "& .kt-portlet__body": {
        border: "1px solid #E6E6E6!important",
        borderRadius: 6,
        p: 0,
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.23)",
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
}));

const UserStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const { user } = useSelector(({ auth }: RootState) => auth);
  const { roles } = useSelector((state: RootState) => state.permission);
  const { languages } = useSelector((state: RootState) => state.settingReducer);
  const classes = useStyles();
  const [addressOptions, setAddressesOptions] = useState<
    Array<AutocompletePrediction>
  >([]);
  const [search, setSearch] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [communes, setCommunes] = useState<ICommune[]>([]);
  const [address, setAddress] = useState<AutocompletePrediction | null>(null);

  useEffect(() => {
    if (search.length > 0) searchAddress();
  }, [search]);
  const boss_commune = roles.find((r: IRole) => r.slug === "boss-commune")?._id;
  const social_worker = roles.find(
    (r: IRole) => r.slug === "social-worker"
  )?._id;

  const searchAddress = () => {
    let service = new google.maps.places.AutocompleteService();

    service.getPlacePredictions(
      {
        input: search,
      },
      (res) => {
        if (res) {
          setAddressesOptions(res);
        }
      }
    );
  };

  useEffect(() => {
    if (selectedRole === boss_commune || selectedRole === social_worker) {
      communeService
        .getAll([
          {
            name: "pagination",
            value: "0",
          },
        ])
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          const { data: communes } = data;
          setCommunes(communes);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [selectedRole]);

  useEffect(() => {
    if (address) {
      form.values.address = address.description;
      form.values.address_json = address;
    }
  }, [address]);

  // const sigPad = {}
  const sigPad = useRef<ReactSignatureCanvas | null>(null);

  const clear = () => {
    if (sigPad) sigPad.current?.clear();
  };

  const getData = () => {
    if (sigPad)
      return sigPad.current?.getTrimmedCanvas().toDataURL("text/html");
  };

  const handleClose = () => {
    props.setDialogue(false);
    setSelectedRole("");
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    userService
      .add(validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        props.setDialogue(false);
        props.onDone();
        setSelectedRole("");
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const { currencies } = useSelector(
    (state: RootState) => state.settingReducer
  );

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          // maxWidth={"md"}
          TransitionComponent={Transition}
          sx={{
            borderRadius: 1,
          }}
        >
          {/*test*/}
          <Box className={classes.container}>
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"USERS.ADD"}
                  defaultMessage={"USERS.ADD"}
                />
              </Typography>
              <Button
                autoFocus
                color="inherit"
                type={"submit"}
                form={"user_form"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"user_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.FIRSTNAME"}
                          defaultMessage={"USERS.FIRSTNAME"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"name"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;
                          form.values.firstname = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"lastname"}
                        label={intl.formatMessage({
                          id: "CASE.INFORMATION.COMMUNE.NAME",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.LASTNAME"}
                          defaultMessage={"USERS.LASTNAME"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"name"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;
                          form.values.lastname = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"lastname"}
                        label={intl.formatMessage({
                          id: "CASE.INFORMATION.COMMUNE.NAME",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"STANDARD.ROLE"}
                          defaultMessage={"STANDARD.ROLE"}
                        />
                      </Typography>
                      <Controls.Select
                        name={"role"}
                        formId={"role"}
                        options={roles.map((role: IRole) => {
                          return {
                            id: role._id.toString(),
                            title: role.title[intl.locale],
                          };
                        })}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.role = event.target.value;
                          setSelectedRole(event.target.value);
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"role"}
                        options={[]}
                        label={intl.formatMessage({ id: "STANDARD.ROLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                {boss_commune === selectedRole ||
                  (social_worker === selectedRole && (
                    <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                      {loaded ? (
                        <Box className={classes.input}>
                          <Typography variant={"body1"}>
                            <FormattedMessage
                              id={"PERMISSION.TITLE.COMMUNES"}
                              defaultMessage={"Communes"}
                            />
                          </Typography>
                          {selectedRole === boss_commune && (
                            <Controls.MultipleSelect
                              label={""}
                              name={"communes"}
                              formId={"communes"}
                              options={communes.map((commune: ICommune) => {
                                return {
                                  id: commune._id.toString(),
                                  title: commune.name,
                                };
                              })}
                              onChange={(event) => {
                                if (!event.target.value) return;

                                form.values.assigned_communes =
                                  event.target.value;
                              }}
                            />
                          )}
                          {selectedRole === social_worker && (
                            <Controls.Select
                              label={""}
                              name={"commune"}
                              formId={"commune"}
                              options={communes.map((commune: ICommune) => {
                                return {
                                  id: commune._id.toString(),
                                  title: commune.name,
                                };
                              })}
                              onChange={(event) => {
                                if (!event.target.value) return;
                                form.values.commune = event.target.value;
                              }}
                            />
                          )}
                        </Box>
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Input
                            name={"description"}
                            label={intl.formatMessage({
                              id: "STANDARD.DESCRIPTION",
                            })}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                  ))}
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"CASE.INFORMATION.EMAIL"}
                          defaultMessage={"CASE.INFORMATION.EMAIL"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"email"}
                        label={""}
                        type={"email"}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.email = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"email"}
                        label={intl.formatMessage({
                          id: "CASE.INFORMATION.EMAIL",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.GENDER"}
                          defaultMessage={"USERS.GENDER"}
                        />
                      </Typography>
                      <Controls.Select
                        name={"gender"}
                        formId={"gender"}
                        options={[
                          {
                            id: "male",
                            title: intl.formatMessage({ id: "USERS.MALE" }),
                          },
                          {
                            id: "female",
                            title: intl.formatMessage({ id: "USERS.FEMALE" }),
                          },
                        ]}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.gender = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"gender"}
                        options={[]}
                        label={intl.formatMessage({ id: "USERS.GENDER" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.BIRTHDAY"}
                          defaultMessage={"USERS.BIRTHDAY"}
                        />
                      </Typography>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker
                          className={classes.datePicker}
                          dropdownClassName={classes.antDropdown}
                          onChange={(value, dateString) => {
                            form.values.birthdate = dateString;
                          }}
                        />
                      </Space>
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Space
                        className={classes.antSpace}
                        direction="vertical"
                        size={12}
                      >
                        <DatePicker />
                      </Space>
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.PHONENUMBER"}
                          defaultMessage={"USERS.PHONENUMBER"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"phone"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.phone = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"phone"}
                        label={intl.formatMessage({ id: "USERS.PHONENUMBER" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.SSN"}
                          defaultMessage={"USERS.SSN"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"ssn"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.ssn = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"ssn"}
                        label={intl.formatMessage({ id: "USERS.SSN" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.POSTNUMBER"}
                          defaultMessage={"USERS.POSTNUMBER"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"zipcode"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.zipcode = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"zipcode"}
                        label={intl.formatMessage({ id: "USERS.POSTNUMBER" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.LANGUAGES"}
                          defaultMessage={"USERS.LANGUAGES"}
                        />
                      </Typography>
                      <Controls.MultipleSelect
                        name={"languages"}
                        formId={"languages"}
                        options={languages
                          ?.sort(function (a, b) {
                            if (
                              a.title[intl.locale]?.toLowerCase() <
                              b.title[intl.locale]?.toLowerCase()
                            )
                              return -1;
                            if (
                              a.title[intl.locale]?.toLowerCase() >
                              b.title[intl.locale]?.toLowerCase()
                            )
                              return 1;
                            return 0;
                          })
                          .map((language) => {
                            return {
                              id: language._id.toString(),
                              title: language.title[intl.locale],
                            };
                          })}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.languages = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"languages"}
                        options={[]}
                        label={intl.formatMessage({ id: "USERS.LANGUAGES" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.PASSWORD"}
                          defaultMessage={"USERS.PASSWORD"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"password"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.password = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"password"}
                        label={intl.formatMessage({ id: "USERS.PASSWORD" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.PRN"}
                          defaultMessage={"USERS.PRN"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"payment_registration_number"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.payment_registration_number =
                            event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"payment_registration_number"}
                        label={intl.formatMessage({ id: "USERS.PRN" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.PAN"}
                          defaultMessage={"USERS.PAN"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"payment_account_number"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.payment_account_number =
                            event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"payment_account_number"}
                        label={intl.formatMessage({ id: "USERS.PAN" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"USERS.PRIVATEEMAIL"}
                          defaultMessage={"USERS.PRIVATEEMAIL"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"private_email"}
                        label={""}
                        type={"email"}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.private_email = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"private_email"}
                        label={intl.formatMessage({ id: "USERS.PRIVATEEMAIL" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                {(user?.role?.slug === "moderator" ||
                  user?.role?.slug === "super-admin") && (
                  <>
                    <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                      {loaded ? (
                        <Box className={classes.input}>
                          <Typography variant={"body1"}>
                            <FormattedMessage
                              id={"USERS.WORK_HOURS"}
                              defaultMessage={"Work hours"}
                            />
                          </Typography>
                          <Controls.Input
                            name={"work_hours"}
                            type={"number"}
                            label={""}
                            onChange={(event) => {
                              if (!event.target.value) return;

                              form.values.work_hours = +event.target.value;
                            }}
                          />
                        </Box>
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Input
                            name={"work_hours"}
                            label={intl.formatMessage({
                              id: "USERS.WORK_HOURS",
                            })}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                    <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                      {loaded ? (
                        <Box className={classes.input}>
                          <Typography variant={"body1"}>
                            <FormattedMessage
                              id={"USERS.WORK_HOURS_TYPE"}
                              defaultMessage={"Work hours type"}
                            />
                          </Typography>
                          <Controls.Select
                            name={"work_hours_type"}
                            formId={"work_hours_type"}
                            options={[
                              {
                                id: "week",
                                title: intl.formatMessage({
                                  id: "WEEK",
                                  defaultMessage: "Week",
                                }),
                              },
                              {
                                id: "month",
                                title: intl.formatMessage({
                                  id: "MONTH",
                                  defaultMessage: "Month",
                                }),
                              },
                            ]}
                            label={""}
                            onChange={(event) => {
                              if (!event.target.value) return;

                              form.values.work_hours_type = event.target.value;
                            }}
                          />
                        </Box>
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Input
                            name={"work_hours_type"}
                            label={intl.formatMessage({
                              id: "USERS.WORK_HOURS_TYPE",
                            })}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                    <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                      <Box className={classes.input}>
                        <Typography variant={"body1"}>
                          <FormattedMessage
                            id={"STANDARD.CURRENCY"}
                            defaultMessage={"Currency"}
                          />
                        </Typography>
                        <Controls.Select
                          options={
                            currencies &&
                            currencies.map((currency) => {
                              return {
                                id: currency.slug,
                                title: currency.currency_title ?? "",
                              };
                            })
                          }
                          formId={"status_pick"}
                          name={"status"}
                          label={""}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.currency = event.target.value;
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                      {loaded ? (
                        <Box className={classes.input}>
                          <Typography variant={"body1"}>
                            <FormattedMessage
                              id={"CASE.INFORMATION.BUDGETLIMIT"}
                            />
                          </Typography>
                          <Controls.Input
                            name={"budget"}
                            type={"number"}
                            label={""}
                            onChange={(event) => {
                              if (!event.target.value) return;

                              form.values.budget = +event.target.value;
                            }}
                          />
                        </Box>
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Input
                            name={"budget"}
                            label={intl.formatMessage({
                              id: "CASE.INFORMATION.BUDGETLIMIT",
                            })}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                  </>
                )}
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"STANDARD.ADDRESS"}
                          defaultMessage={"STANDARD.ADDRESS"}
                        />
                      </Typography>
                      <Autocomplete
                        fullWidth
                        disablePortal
                        getOptionLabel={(
                          option: AutocompletePrediction | string
                        ) =>
                          typeof option === "string"
                            ? option
                            : option.description
                        }
                        filterOptions={(x) => x}
                        id="google-map"
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        options={addressOptions}
                        onChange={(
                          event: any,
                          newValue: AutocompletePrediction | null
                        ) => {
                          setAddress(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            onChange={(e) => setSearch(e.target.value)}
                            {...params}
                            value={search}
                            label={""}
                            fullWidth
                          />
                        )}
                        renderOption={(
                          props,
                          option: AutocompletePrediction
                        ) => {
                          const text = option.description;

                          return (
                            <li {...props}>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <Box
                                    component={LocationOnOutlinedIcon}
                                    sx={{ color: "text.secondary", mr: 2 }}
                                  />
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="body2" color="text.main">
                                    {text}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </li>
                          );
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"place"}
                        label={intl.formatMessage({ id: "USERS.PLACE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                {/*<Grid item sm={6} xs={6} md={6} lg={6} xl={6}>*/}
                {loaded ? (
                  <Box className={classes.signature}>
                    <Box sx={{ display: "flex" }}>
                      <Typography variant={"body1"} sx={{ flex: 1 }}>
                        <FormattedMessage
                          id={"USERS.SIGNATURE"}
                          defaultMessage={"USERS.SIGNATURE"}
                        />
                      </Typography>
                      <Button color={"error"} onClick={() => clear()}>
                        <FormattedMessage
                          id={"STANDARD.CLEAR"}
                          defaultMessage={"STANDARD.CLEAR"}
                        />
                      </Button>
                    </Box>
                    <Module title={""}>
                      <SignatureCanvas
                        penColor="black"
                        ref={(node) => {
                          sigPad.current = node;
                        }}
                        canvasProps={{
                          width: 500,
                          height: 160,
                          className: "sigCanvas",
                        }}
                        onEnd={() => {
                          form.values.signature = getData() ?? "";
                        }}
                      />
                    </Module>
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"signature"}
                      label={intl.formatMessage({ id: "USERS.SIGNATURE" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
                {/*</Grid>*/}
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default UserStore;
