import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import Controls from "app/widgets/uncontrolled";
import { Skeleton } from "@mui/lab";
import { aiservice, caseService, documentService } from "app/services";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import Snackbar from "app/widgets/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { IUser } from "../../../interfaces";
import { IFormUsersValues } from "../store/parts/users";
import { INote } from "../../../services/note.service";
import { FormattedMessage, useIntl } from "react-intl";
import { PermissionsGate } from "../../../permission/PermissionsGate";
import ConfirmDialog from "../../../partials/dialogues/confirmDialogue";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";

interface IFormProps {
  _case: string;
  _id: string;
  focus_type?: string;
  focus_area?: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  description: string;
  description_org: string;
  users: Array<IUser>;
  documents?: Array<File>;
} & IFormUsersValues;

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
}));

const ShowCaseNote = (props: IFormProps) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>();
  const classes = useStyles();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [note, setNote] = useState<INote>();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [editable, setEditable] = useState<boolean>(false);
  const [orgDesc, setOrgDesc] = useState<string>("");
  const [noteDesc, setNoteDesc] = useState<string>("");

  const languageCodes: any = {
    en: "en-US",
    ar: "ar-AR",
    zh: "zh-CN",
    cs: "cs-CZ",
    da: "da-DK",
    nl: "nl-NL",
    fi: "fi-FI",
    fr: "fr-FR",
    de: "de-DE",
    el: "el-GR",
    hi: "hi-IN",
    hu: "hu-HU",
    it: "it-IT",
    ja: "ja-JP",
    ko: "ko-KR",
    pl: "pl-PL",
    pt: "pt-BR",
    ru: "ru-RU",
    es: "es-ES",
    sv: "sv-SE",
    tr: "tr-TR",
    vi: "vi-VN",
    az: "az-AZ",
  };

  const current = languageCodes[intl.locale] || languageCodes.en;
  const [isNoteListening, setIsNoteListening] = useState(false);
  const lastProcessedTranscriptShow = useRef("");
  const lastNoteProcessedTranscriptShow = useRef("");
  const recognitionNote = useRef<SpeechRecognition | null>(null);
  const [noteTranscript, setNoteTranscript] = useState("");
  const noteShowDescRef = useRef<HTMLInputElement>(null);
  const [noteEditable, setNoteEditable] = useState<boolean>(false);
  const [noteOrgDescAfter, setNoteOrgDescAfter] = useState<string>("");
  const [draftDesc, setDraftDesc] = useState<string>("");

  useEffect(() => {
    if (
      !("SpeechRecognition" in window || "webkitSpeechRecognition" in window)
    ) {
      Snackbar.error("Your browser does not support speech recognition.");
      return;
    }

    // Setup recognition for Note
    recognitionNote.current = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition)();
    recognitionNote.current.continuous = true;
    recognitionNote.current.interimResults = false;
    recognitionNote.current.lang = current;

    recognitionNote.current.onresult = (event) => {
      let combinedTranscript = "";
      for (let i = 0; i < event.results.length; i++) {
        combinedTranscript += event.results[i][0].transcript;
      }
      setNoteTranscript(combinedTranscript);
    };

    recognitionNote.current.onerror = (event) => {
      Snackbar.error(`Note Description Recognition Error: ${event.error}`);
    };

    // Cleanup on unmount
    return () => {
      if (recognitionNote.current) {
        recognitionNote.current.stop();
        recognitionNote.current = null;
      }
    };
  }, [current]);

  const handleStart = () => {
    if (recognitionNote.current) {
      recognitionNote.current.start();
      lastNoteProcessedTranscriptShow.current = "";
      setNoteTranscript("");
      setIsNoteListening(true);
    }
  };

  const handleStop = () => {
    if (recognitionNote.current) {
      recognitionNote.current.stop();
      setIsNoteListening(false);
    }
  };

  const handleReset = () => {
    setDraftDesc("");
    setNoteTranscript("");
    lastProcessedTranscriptShow.current = "";
    lastNoteProcessedTranscriptShow.current = "";
    if (noteShowDescRef.current) {
      noteShowDescRef.current.value = "";
    }
    setEditable(false);
    setNoteEditable(false);
    setOrgDesc("");
    setNoteOrgDescAfter("");
  };

  useEffect(() => {
    if (noteTranscript.length > 0) {
      const newText = noteTranscript
        .replace(lastNoteProcessedTranscriptShow.current, "")
        .trim();
      if (newText) {
        if (noteShowDescRef.current) {
          noteShowDescRef.current.value =
            noteShowDescRef.current.value + " " + newText;
          setNoteDesc(noteShowDescRef.current.value);
          form.values.description = noteShowDescRef.current.value;
        }

        lastNoteProcessedTranscriptShow.current = noteTranscript;
      }
    }
  }, [noteTranscript]);

  useEffect(() => {
    if (loading) return;
    caseService
      .getOneNote(props._case, props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: note } = data;
        setNote(note);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._case, props._id, loading]);

  const handleClose = () => {
    handleReset();
    props.setDialogue(false);
  };

  useEffect(() => {
    setNoteOrgDescAfter(orgDesc);
  }, [orgDesc]);

  const deleteDocument = (documentId: string) => {
    setLoading(true);
    documentService
      .delete(documentId)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
      })
      .catch((e) => {
        Snackbar.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    form.values.description_org = orgDesc;
    caseService
      .updateNote(props._case, props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
        props.setDialogue(false);
        props.onDone();
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleGrammar = () => {
    if (form.values.description.length < 1) {
      Snackbar.error(
        intl.formatMessage({
          id: "SNACKBAR.TEXTERROR",
          defaultMessage: "Please enter a text",
        })
      );
      return;
    }

    aiservice.grammar({ text: form.values.description }).then((data) => {
      setEditable(true);
      if (data.corrected_text) {
        setOrgDesc(data.corrected_text);
        setNoteOrgDescAfter(data.corrected_text);
      }
    });
  };

  const handleHighLevelGrammar = () => {
    if (form.values.description.length < 1) {
      Snackbar.error(
        intl.formatMessage({
          id: "SNACKBAR.TEXTERROR",
          defaultMessage: "Please enter a text",
        })
      );
      return;
    }

    aiservice
      .highlevelgrammar({
        text: form.values.description,
        focus_type: props.focus_type ?? "",
        focus_area: props.focus_area ?? "",
      })
      .then((data) => {
        setEditable(true);

        if (data.corrected_text) {
          setOrgDesc(data.corrected_text);
          setNoteOrgDescAfter(data.corrected_text);
        }
      });
  };

  useEffect(() => {
    if (note) {
      form.values.title = note.title;
      form.values.description = note.description;
      form.values.description_org = note.description_org;
      if (note.description_org) {
        setEditable(true);
        setOrgDesc(note.description_org);
      }
      if (noteShowDescRef.current) {
        noteShowDescRef.current.value = note.description;
      }
    }
  }, [note]);

  return (
    <>
      <PermissionsGate section={"notes"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete document?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              deleteDocument(confirmId);
            }}
          >
            Are you sure you want to delete this document?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#E0F2FF",
            boxShadow: "none",
            p: "0px 20px",
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              <FormattedMessage
                id={"CASE.NOTE.SHOW"}
                defaultMessage={"CASE.NOTE.SHOW"}
              />
            </Typography>
            <Button
              onClick={() => {
                handleGrammar();
              }}
              sx={{ marginRight: "10px" }}
              variant="contained"
            >
              {intl.formatMessage({
                id: "STANDARD.GRAMMAR_CORRECTION",
                defaultMessage: "Grammar correction",
              })}
            </Button>
            <Button
              onClick={() => {
                handleHighLevelGrammar();
              }}
              sx={{ marginRight: "10px" }}
              variant="contained"
            >
              {intl.formatMessage({
                id: "STANDARD.HIGHER_LEVEL",
                defaultMessage: "Higher level correction",
              })}
            </Button>
            <Button
              autoFocus
              data-cy-class={"note_form"}
              data-cy-case-id={props._case}
              data-cy-finance-id={props._id}
              color="inherit"
              type={"submit"}
              form={"note_form"}
              sx={{
                backgroundColor: "#0D99FF",
                borderRadius: 2,
                color: "#ffffff",
              }}
            >
              <FormattedMessage
                id={"DIALOGUE.SAVE"}
                defaultMessage={"DIALOGUE.SAVE"}
              />
            </Button>
          </Toolbar>
        </Box>
        <DialogContent>
          <form
            id={"note_form"}
            onSubmit={(e) => form.handleSubmit(e, handleSave)}
          >
            <Grid container spacing={2} mb={2}>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded && note ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"STANDARD.TITLE"}
                        defaultMessage={"STANDARD.TITLE"}
                      />
                    </Typography>
                    <Controls.Input
                      name={"title"}
                      label={""}
                      defaultValue={note?.title}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.title = event.target.value;
                      }}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"title"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={editable ? 6 : 12}
                lg={editable ? 6 : 12}
                xl={editable ? 6 : 12}
              >
                {loaded && note ? (
                  <Box className={classes.input}>
                    <Button
                      onClick={() => {
                        isNoteListening ? handleStop() : handleStart();
                      }}
                      style={{
                        padding: "10px",
                        borderRadius: "50%",
                        border: "none",
                        cursor: "pointer",
                      }}
                      sx={{ marginRight: "10px" }}
                      variant="outlined"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Layer_1"
                        data-name="Layer 1"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill={isNoteListening ? "red" : "green"}
                          d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm-2,7c0-1.105.895-2,2-2s2,.895,2,2v5c0,1.105-.895,2-2,2s-2-.895-2-2v-5Zm3,10.916v1.084c0,.552-.447,1-1,1s-1-.448-1-1v-1.084c-2.834-.477-5-2.948-5-5.916,0-.552.447-1,1-1s1,.448,1,1c0,2.206,1.794,4,4,4s4-1.794,4-4c0-.552.447-1,1-1s1,.448,1,1c0,2.968-2.166,5.439-5,5.916Z"
                        />
                      </svg>

                      <span
                        style={{
                          color: isNoteListening ? "red" : "green",
                          marginLeft: "10px",
                        }}
                      >
                        {isNoteListening
                          ? intl.formatMessage({
                              id: "STANDARD.STOP_LISTENING",
                              defaultMessage: "Stop Listening",
                            })
                          : intl.formatMessage({
                              id: "STANDARD.START_LISTENING",
                              defaultMessage: "Start Listening",
                            })}
                      </span>
                    </Button>

                    <Button
                      onClick={() => {
                        handleReset();
                      }}
                      style={{
                        padding: "5px",
                        border: "none",
                        background: "red",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      sx={{ marginRight: "10px" }}
                      variant="outlined"
                    >
                      {intl.formatMessage({
                        id: "STANDARD.CLEAR",
                        defaultMessage: "Clear",
                      })}
                    </Button>
                    <Controls.Input
                      multiline={true}
                      rows={5}
                      name={"description"}
                      disabled={editable}
                      inputRef={noteShowDescRef}
                      label={""}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.description = event.target.value;
                        if (noteShowDescRef.current)
                          noteShowDescRef.current.value = event.target.value;
                      }}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      rows={5}
                      name={"description"}
                      label={intl.formatMessage({ id: "STANDARD.DESCRIPTION" })}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              {editable && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={editable ? 6 : 12}
                  lg={editable ? 6 : 12}
                  xl={editable ? 6 : 12}
                >
                  {loaded && note ? (
                    <Box className={classes.input}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          height: 44,
                        }}
                      >
                        <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                          {noteEditable
                            ? intl.formatMessage({
                                id: "STANDARD.ORIGINAL",
                                defaultMessage: "Original",
                              })
                            : intl.formatMessage({
                                id: "STANDARD.DESCRIPTION",
                                defaultMessage: "STANDARD.DESCRIPTION",
                              })}
                        </Typography>
                      </Box>
                      <Controls.Input
                        multiline={true}
                        rows={5}
                        name={"description"}
                        label={""}
                        defaultValue={noteOrgDescAfter}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.description_org = event.target.value;
                          setNoteOrgDescAfter(event.target.value);
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        rows={5}
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              )}
              {note?.activities && (
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  <Box className=" kt-timeline-v2-forcase">
                    <Box className="kt-timeline-v2__items-forcase">
                      {note?.activities.map((activity) => {
                        const description = intl.formatMessage({
                          id: activity.title,
                        });
                        const descriptionArray = description.split("-");
                        const title = descriptionArray[0].replace(
                          "{who}",
                          activity.replacement.title
                        );
                        return (
                          <Box className="kt-timeline-v2__item">
                            <Box className="kt-timeline-v2__item-cricle">
                              <Avatar
                                variant="circular"
                                sx={{ width: 24, height: 24 }}
                                src={activity.replacement.avatar}
                                alt={title}
                              />
                            </Box>
                            <Box className="kt-timeline-v2__item-text kt-padding-top-5">
                              <Typography variant={"body1"}>
                                <b style={{ color: "#556EE6" }}>
                                  <a
                                    onClick={() => {
                                      history.push(
                                        `/users/${activity.replacement.id}`
                                      );
                                    }}
                                  >
                                    {title}
                                  </a>
                                </b>
                                {" - "} {descriptionArray[1]}
                              </Typography>
                              <Typography>
                                {moment
                                  .unix(activity.created_at)
                                  .local()
                                  .format("DD-MM-YYYY HH:mm")}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowCaseNote;
