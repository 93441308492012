import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
  IUser,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";
import { IDocument } from "./document.service";

export interface INote {
  _id: string;
  title: string;
  description: string;
  description_org: string;
  users: Array<IUser>;
  documents?: Array<IDocument>;
  activities?: Array<{
    title: string;
    description: string;
    replacement: {
      id: string;
      is_link: number;
      key: string;
      title: string;
      avatar: string;
    };
    created_at: number;
  }>;
  created_by?: IUser;
  updated_by?: IUser;
  _created_at: number;
  _updated_at: number;
}

export interface INoteStore {
  title: string;
  description: string;
  description_org: string;
  users: Array<string>;
  files?: Array<string>;
}

export interface INoteShow {
  _id: string;
  title: string;
  description: string;
  users: Array<IUser>;
  _created_at: string;
  _updated_at: string;
}

export interface INoteQuickCardsCount {
  data: {
    moderator: number;
    employee: number;
  };
}

const INDEX = API_ROUTES.folder_notes;
const SHOW = API_ROUTES.folder_note;
const UPDATE = API_ROUTES.folder_note;
const STORE = API_ROUTES.folder_notes;
const DELETE = API_ROUTES.folder_note;
const FOLDER_COUNT = API_ROUTES.folders_count;
const COUNT = API_ROUTES.folder_notes_count;

export interface INoteService {
  getAll(
    folderId: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<any> | IRestApiError>;
  getOne(
    folderId: string,
    _id: string
  ): Promise<IRestApiResource<INote> | IRestApiError>;
  update(
    folderId: string,
    _id: string,
    data: INoteStore,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<INoteShow> | IRestApiError>;
  delete(
    folderId: string,
    _id: string
  ): Promise<IRestApiCollection<INote> | IRestApiError>;
  add(
    folderId: string,
    data: any,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;
  getQuickCardsCount(
    query?: Array<IUrlQuery>
  ): Promise<INoteQuickCardsCount | IRestApiError>;
  getFoldersQuickCardsCount(
    query?: Array<IUrlQuery>
  ): Promise<INoteQuickCardsCount | IRestApiError>;
}

const noteService: INoteService = {
  async getAll(folderId: string, queries: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(INDEX).replace(
        ":folderId",
        folderId.toString()
      )}${queriesString}`,
      "get"
    );
  },

  async getOne(folderId: string, _id: string) {
    return http(
      `${getRoute(SHOW)
        .replace(":folderId", folderId.toString())
        .replace(":_id", _id.toString())}`,
      "get"
    );
  },

  async update(
    folderId: string,
    _id: string,
    data: INoteStore,
    queries?: Array<IUrlQuery>
  ) {
    let jsonData = JSON.stringify(data);
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(UPDATE)
        .replace(":folderId", folderId.toString())
        .replace(":_id", _id.toString())}${queriesString}`,
      "put",
      jsonData
    );
  },

  async delete(folderId: string, _id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(DELETE)
        .replace(":folderId", folderId.toString())
        .replace(":_id", _id.toString())}${queriesString}`,
      "delete"
    );
  },

  async add(folderId: string, data: any, queries?: Array<IUrlQuery>) {
    let formData = generateFormData(new FormData(), data);
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(STORE).replace(
        ":folderId",
        folderId.toString()
      )}${queriesString}`,
      "post",
      formData
    );
  },

  async getQuickCardsCount(query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(COUNT)}${queryString}`, "get");
  },

  async getFoldersQuickCardsCount(query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(FOLDER_COUNT)}${queryString}`, "get");
  },
};

export { noteService };
