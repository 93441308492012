import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "../../../partials/layout/DataTable";
import { Chip, IconButton } from "@mui/material";
import { settingService } from "../../../services";
import TimeTrackTypeStore from "./store";
import { useTable } from "../../../hooks/useTable";
import { ISetting } from "../../../services/setting.service";
import { GridTable } from "../../../partials/layout/GridTable";
import ShowTimetrackType from "./show";
import { useIntl } from "react-intl";
import ConfirmDialog from "../../../partials/dialogues/confirmDialogue";
import { usePermissions } from "../../../hooks/useRole";
import { hasPermission } from "../../../permission/PermissionsGate";
import EditIcon from "../../../partials/icons/EditIcon";
import DeleteIcon from "../../../partials/icons/DeleteIcon";

const TimeTrackType = () => {
  const intl = useIntl();
  const permissions = usePermissions();
  const { data, xhrLoading, refresh, remove, updateQuery } = useTable<ISetting>(
    {
      fetch: settingService.get,
      delete: settingService.delete,
      query: [
        {
          name: "type",
          value: "time_track_types",
        },
        {
          name: "per_page",
          value: 5,
        },
      ],
    }
  );
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 200,
    },
    {
      field: "title",
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      valueGetter: (params) => {
        return params.row?.title && params.row?.title[intl.locale];
      },
      minWidth: 250,
    },
    {
      field: "status",
      headerAlign: "right",
      align: "right",
      headerName: intl.formatMessage({
        id: "STANDARD.FEEDBACK_ACTIVE",
        defaultMessage: "Feedback active",
      }),
      renderCell: (params) => {
        const status = +params.row?.feedback_active;
        if (status === 1) {
          return (
            <Chip
              label={intl.formatMessage({
                id: "STANDARD.ACTIVE",
                defaultMessage: "Active",
              })}
              sx={{
                backgroundColor: "#5ECC62",
                color: "#ffffff",
              }}
            />
          );
        } else {
          return (
            <Chip
              label={intl.formatMessage({
                id: "STANDARD.DEACTIVE",
                defaultMessage: "Deactive",
              })}
              sx={{
                backgroundColor: "#DF475E",
                color: "#ffffff",
              }}
            />
          );
        }
      },
      minWidth: 200,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => show(params.row?._id)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleClick(params.row?._id)}>
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [timeTrackTypeId, setTimeTrackTypeId] = useState<string | null>(null);

  const show = (_id: string) => {
    setTimeTrackTypeId(_id);
  };

  const [confirmId, setConfirmId] = useState<string | null>(null);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  return (
    <>
      {confirmId ? (
        <ConfirmDialog
          title="Delete time registration type?"
          open={Boolean(confirmId)}
          setOpen={() => setConfirmId(null)}
          onConfirm={() => {
            remove(confirmId);
          }}
        >
          Are you sure you want to delete this time registration type?
        </ConfirmDialog>
      ) : (
        <></>
      )}
      {timeTrackTypeId ? (
        <ShowTimetrackType
          _id={timeTrackTypeId}
          open={Boolean(timeTrackTypeId)}
          setDialogue={() => setTimeTrackTypeId(null)}
          onDone={refresh}
        />
      ) : (
        <></>
      )}
      <GridTable
        loading={xhrLoading}
        skeleton={!init}
        pageSize={data?.per_page}
        count={data?.total}
        columns={columns}
        rows={data?.data ?? []}
        tableRef={tableRef}
        filterModel={undefined}
        search={(queries) => updateQuery(queries)}
        query={(queries) => updateQuery(queries)}
        filterToolbar={{
          actionPanel:
            permissions && hasPermission(permissions, "settings", "create")
              ? (props: { open: boolean; close: () => void }) => {
                  return (
                    <TimeTrackTypeStore
                      open={props.open}
                      setDialogue={props.close}
                      onDone={refresh}
                    />
                  );
                }
              : undefined,
        }}
      />
    </>
  );
};

export default TimeTrackType;
