import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useForm } from "../../../hooks/useForm";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "../../../widgets/uncontrolled";
import { IFormUsersValues } from "./parts/users";
import Snackbar from "../../../widgets/Snackbar";
import { aiservice, caseService } from "../../../services";
import { IUser } from "../../../interfaces";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";

interface IFormProps {
  _id: string;
  open: boolean;
  focus_type?: string;
  focus_area?: string;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  description: string;
  description_org: string;
  users: Array<IUser>;
  documents?: Array<File>;
} & IFormUsersValues;

const initValues = {
  title: "",
  description: "",
  description_org: "",
  users: [],
  documents: [],
} as FormStateValues;

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
}));

const NoteStore: FC<IFormProps> = (props) => {
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const intl = useIntl();
  const classes = useStyles();
  const [editable, setEditable] = useState<boolean>(false);
  const [orgDesc, setOrgDesc] = useState<string>("");

  const handleClose = () => {
    props.setDialogue(false);
    setOrgDesc("");
    handleReset();
    setEditable(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }
    form.values.description_org = orgDesc;

    caseService
      .postNote(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const handleGrammar = () => {
    if (form.values.description.length < 1) {
      Snackbar.error(
        intl.formatMessage({
          id: "SNACKBAR.TEXTERROR",
          defaultMessage: "Please enter a text",
        })
      );
      return;
    }

    aiservice.grammar({ text: form.values.description }).then((data) => {
      setEditable(true);
      if (data.corrected_text) setOrgDesc(data.corrected_text);
    });
  };

  const handleHighLevelGrammar = () => {
    if (form.values.description.length < 1) {
      Snackbar.error(
        intl.formatMessage({
          id: "SNACKBAR.TEXTERROR",
          defaultMessage: "Please enter a text",
        })
      );
      return;
    }

    aiservice
      .highlevelgrammar({
        text: form.values.description,
        focus_type: props.focus_type ?? "",
        focus_area: props.focus_area ?? "",
      })
      .then((data) => {
        setEditable(true);

        if (data.corrected_text) setOrgDesc(data.corrected_text);
      });
  };

  const languageCodes: any = {
    en: "en-US",
    ar: "ar-AR",
    zh: "zh-CN",
    cs: "cs-CZ",
    da: "da-DK",
    nl: "nl-NL",
    fi: "fi-FI",
    fr: "fr-FR",
    de: "de-DE",
    el: "el-GR",
    hi: "hi-IN",
    hu: "hu-HU",
    it: "it-IT",
    ja: "ja-JP",
    ko: "ko-KR",
    pl: "pl-PL",
    pt: "pt-BR",
    ru: "ru-RU",
    es: "es-ES",
    sv: "sv-SE",
    tr: "tr-TR",
    vi: "vi-VN",
    az: "az-AZ",
  };

  const current = languageCodes[intl.locale] || languageCodes.en;
  const [isNoteListening, setIsNoteListening] = useState(false);
  const lastProcessedTranscriptShow = useRef("");
  const lastNoteProcessedTranscriptShow = useRef("");
  const recognitionTodo = useRef<SpeechRecognition | null>(null);
  const recognitionNote = useRef<SpeechRecognition | null>(null);
  const [transcript, setTranscript] = useState("");
  const [noteTranscript, setNoteTranscript] = useState("");
  const noteDescRef = useRef<HTMLInputElement>(null);
  const [draftDesc, setDraftDesc] = useState<string>("");
  const [noteEditable, setNoteEditable] = useState<boolean>(false);
  const [noteDesc, setNoteDesc] = useState<string>("");

  useEffect(() => {
    if (
      !("SpeechRecognition" in window || "webkitSpeechRecognition" in window)
    ) {
      Snackbar.error("Your browser does not support speech recognition.");
      return;
    }
    // Setup recognition for Note
    recognitionNote.current = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition)();
    recognitionNote.current.continuous = true;
    recognitionNote.current.interimResults = false;
    recognitionNote.current.lang = current;

    recognitionNote.current.onresult = (event) => {
      let combinedTranscript = "";
      for (let i = 0; i < event.results.length; i++) {
        combinedTranscript += event.results[i][0].transcript;
      }
      setNoteTranscript(combinedTranscript);
    };

    recognitionNote.current.onerror = (event) => {
      Snackbar.error(`Note Description Recognition Error: ${event.error}`);
    };

    // Cleanup on unmount
    return () => {
      if (recognitionTodo.current) {
        recognitionTodo.current.stop();
        recognitionTodo.current = null;
      }
      if (recognitionNote.current) {
        recognitionNote.current.stop();
        recognitionNote.current = null;
      }
    };
  }, [current]);

  const handleStart = () => {
    if (recognitionNote.current) {
      recognitionNote.current.start();
      lastNoteProcessedTranscriptShow.current = "";
      setNoteTranscript("");
      setIsNoteListening(true);
    }
  };

  const handleStop = () => {
    if (recognitionNote.current) {
      recognitionNote.current.stop();
      setIsNoteListening(false);
    }
  };

  const handleReset = () => {
    setDraftDesc("");
    form.clear();
    setTranscript("");
    setNoteTranscript("");
    lastProcessedTranscriptShow.current = "";
    lastNoteProcessedTranscriptShow.current = "";

    if (noteDescRef.current) noteDescRef.current.value = "";
    setEditable(false);
    setNoteEditable(false);
  };

  useEffect(() => {
    if (noteTranscript.length > 0) {
      const newText = noteTranscript
        .replace(lastNoteProcessedTranscriptShow.current, "")
        .trim();
      if (newText) {
        if (noteDescRef.current) {
          noteDescRef.current.value = noteDescRef.current.value + " " + newText;
          setNoteDesc(noteDescRef.current.value);
          form.values.description = noteDescRef.current.value;
        }

        lastNoteProcessedTranscriptShow.current = noteTranscript;
      }
    }
  }, [noteTranscript]);

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"md"}
          TransitionComponent={Transition}
          sx={{
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#E0F2FF",
              boxShadow: "none",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"CASE.NOTE.ADD"}
                  defaultMessage={"CASE.NOTE.ADD"}
                />
              </Typography>
              <Button
                onClick={() => {
                  handleGrammar();
                }}
                sx={{ marginRight: "10px" }}
                variant="contained"
              >
                {intl.formatMessage({
                  id: "STANDARD.GRAMMAR_CORRECTION",
                  defaultMessage: "Grammar correction",
                })}
              </Button>
              <Button
                onClick={() => {
                  handleHighLevelGrammar();
                }}
                sx={{ marginRight: "10px" }}
                variant="contained"
              >
                {intl.formatMessage({
                  id: "STANDARD.HIGHER_LEVEL",
                  defaultMessage: "Higher level correction",
                })}
              </Button>
              <Button
                autoFocus
                color="inherit"
                type={"submit"}
                form={"note_form"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"note_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        <FormattedMessage
                          id={"STANDARD.TITLE"}
                          defaultMessage={"STANDARD.TITLE"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"title"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.title = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={editable ? 6 : 12}
                  lg={editable ? 6 : 12}
                  xl={editable ? 6 : 12}
                >
                  {loaded ? (
                    <Box className={classes.input}>
                      <Button
                        onClick={() => {
                          isNoteListening ? handleStop() : handleStart();
                        }}
                        style={{
                          padding: "10px",
                          borderRadius: "50%",
                          border: "none",
                          cursor: "pointer",
                        }}
                        sx={{ marginRight: "10px" }}
                        variant="outlined"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_1"
                          data-name="Layer 1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill={isNoteListening ? "red" : "green"}
                            d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm-2,7c0-1.105.895-2,2-2s2,.895,2,2v5c0,1.105-.895,2-2,2s-2-.895-2-2v-5Zm3,10.916v1.084c0,.552-.447,1-1,1s-1-.448-1-1v-1.084c-2.834-.477-5-2.948-5-5.916,0-.552.447-1,1-1s1,.448,1,1c0,2.206,1.794,4,4,4s4-1.794,4-4c0-.552.447-1,1-1s1,.448,1,1c0,2.968-2.166,5.439-5,5.916Z"
                          />
                        </svg>

                        <span
                          style={{
                            color: isNoteListening ? "red" : "green",
                            marginLeft: "10px",
                          }}
                        >
                          {isNoteListening
                            ? intl.formatMessage({
                                id: "STANDARD.STOP_LISTENING",
                                defaultMessage: "Stop Listening",
                              })
                            : intl.formatMessage({
                                id: "STANDARD.START_LISTENING",
                                defaultMessage: "Start Listening",
                              })}
                        </span>
                      </Button>

                      <Button
                        onClick={() => {
                          handleReset();
                        }}
                        style={{
                          padding: "5px",
                          border: "none",
                          background: "red",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        sx={{ marginRight: "10px" }}
                        variant="outlined"
                      >
                        {intl.formatMessage({
                          id: "STANDARD.CLEAR",
                          defaultMessage: "Clear",
                        })}
                      </Button>

                      <Controls.Input
                        multiline={true}
                        rows={5}
                        disabled={editable}
                        inputRef={noteDescRef}
                        name={"description"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.description = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        rows={5}
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                {editable && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={editable ? 6 : 12}
                    lg={editable ? 6 : 12}
                    xl={editable ? 6 : 12}
                  >
                    {loaded ? (
                      <Box className={classes.input}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            height: 44,
                          }}
                        >
                          <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                            {noteEditable
                              ? intl.formatMessage({
                                  id: "STANDARD.ORIGINAL",
                                  defaultMessage: "Original",
                                })
                              : intl.formatMessage({
                                  id: "STANDARD.DESCRIPTION",
                                  defaultMessage: "STANDARD.DESCRIPTION",
                                })}
                          </Typography>
                        </Box>
                        <Controls.Input
                          multiline={true}
                          rows={5}
                          name={"description"}
                          label={""}
                          defaultValue={orgDesc}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.description_org = event.target.value;
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          rows={5}
                          name={"description"}
                          label={intl.formatMessage({
                            id: "STANDARD.DESCRIPTION",
                          })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                )}
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default NoteStore;
