import {
  IUrlQuery,
  IUser,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";
import { ISetting } from "./setting.service";
import { ICase } from "./case.service";
import { INote, INoteStore } from "./note.service";

export interface ITodo {
  _id: string;
  title: string;
  type: ISetting;
  case: ICase;
  category: ISetting;
  description: string;
  description_org: string;
  lead: IUser;
  users: Array<IUser>;
  date: number;
  start_time: string;
  deadline_date: number;
  end_time: string;
  repeat: {
    every: number;
    type: string;
    days?: string[];
    end: { type: string; value: string };
  };
  generation: {
    type: string;
    end: { type: string; value: number };
  };
  status: ISetting;
  _created_at: number;
  _updated_at: string;
}

interface ITodoStoreDataSignature {
  [key: string]: any;
}

export interface ITodoStore extends ITodoStoreDataSignature {
  title: string | null;
  category: string;
  type: string;
  users: Array<string>;
  lead: string;
  description: string;
  date: string;
  deadline_date: string;
  status: string;
}

export interface ITodoQuickCardsCount {
  data: {
    expired: number;
    not_started: number;
    todos: number;
    events: number;
  };
}

const INDEX = API_ROUTES.todos;
const MIN_LIST = API_ROUTES.todos_minlist;
const SHOW = API_ROUTES.todo;
const UPDATE = API_ROUTES.todo;
const STORE = API_ROUTES.todos;
const DELETE = API_ROUTES.todo_delete;
const NOTES_INDEX = API_ROUTES.todo_notes;
const SHOW_NOTE = API_ROUTES.todo_note;

const QUICK_CARDS_COUNT = API_ROUTES.todos_count;

export interface ITodoService {
  getMinList(
    queries?: Array<IUrlQuery>
  ): Promise<{ data: any } | IRestApiError>;
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITodo> | IRestApiError>;
  getQuickCardsCount(
    query?: Array<IUrlQuery>
  ): Promise<ITodoQuickCardsCount | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<ITodo> | IRestApiError>;
  update(
    _id: string | undefined,
    data: ITodoStore,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;
  delete(_id: string): Promise<IRestApiCollection<ITodo> | IRestApiError>;
  add(data: ITodoStore): Promise<IRestApiResource<ITodo> | IRestApiError>;
  getNotes(_id: string): Promise<IRestApiCollection<INote> | IRestApiError>;
  addNote(
    _id: string,
    data: INoteStore
  ): Promise<IRestApiResource<INote> | IRestApiError>;
  getNote(
    todo: string,
    _id: string
  ): Promise<IRestApiResource<INote> | IRestApiError>;
  updateNote(
    todo: string,
    _id: string,
    data: INoteStore,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;
  deleteNote(
    todo: string,
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;
}

const todoService: ITodoService = {
  async getMinList(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(MIN_LIST)}${queriesString}`, "get");
  },
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async getQuickCardsCount(query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(QUICK_CARDS_COUNT)}${queryString}`, "get");
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id: string, data: any, query?: Array<IUrlQuery>) {
    let jsonData = JSON.stringify(data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}${queryString}`,
      "put",
      jsonData
    );
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(DELETE.replace(":_id", _id.toString()))}${queriesString}`,
      "delete"
    );
  },

  async add(data: ITodoStore) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(STORE), "post", formData);
  },

  async getNotes(_id: string) {
    return http(
      `${getRoute(NOTES_INDEX).replace(":_id", _id.toString())}`,
      "get"
    );
  },

  async addNote(_id: string, data: INoteStore) {
    let formData = generateFormData(new FormData(), data);
    return http(
      `${getRoute(NOTES_INDEX).replace(":_id", _id.toString())}`,
      "post",
      formData
    );
  },

  async getNote(todo: string, _id: string) {
    return http(
      `${getRoute(SHOW_NOTE)
        .replace(":todo", todo.toString())
        .replace(":_id", _id.toString())}`,
      "get"
    );
  },

  async updateNote(
    todo: string,
    _id: string,
    data: any,
    query?: Array<IUrlQuery>
  ) {
    let jsonData = JSON.stringify(data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(SHOW_NOTE)
        .replace(":todo", todo.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "put",
      jsonData
    );
  },

  async deleteNote(todo: string, _id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(SHOW_NOTE)
        .replace(":todo", todo.toString())
        .replace(":_id", _id.toString())}${queriesString}`,
      "delete"
    );
  },
};

export { todoService };
