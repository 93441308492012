import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useRef,
} from "react";
import Snackbar from "../../../widgets/Snackbar";
import { settingService } from "../../../services";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as setting from "../../../store/ducks/setting.duck";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import Controls from "../../../widgets/uncontrolled";
import { Palette } from "../../../widgets/Palette";
import { useForm } from "../../../hooks/useForm";
import { RootState } from "../../../store/store";
import { TransitionProps } from "@mui/material/transitions";

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: { [key: string]: string } | null;
  color: string;
  active: number;
  feedback_active: number;
};

const initValues = {
  title: null,
  active: 1,
  feedback_active: 1,
  color: "#000",
} as FormStateValues;

const TimeTrackTypeStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const addSetting = useCallback(
    (s) => dispatch({ type: setting.actionTypes.Add, payload: s }),
    [dispatch]
  );

  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const { system_languages } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const colorRef = useRef<HTMLInputElement>();

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    settingService
      .add(validated, [
        {
          name: "type",
          value: "user_session_types",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        let { data: setting } = data;
        addSetting(setting);

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "rgb(245, 245, 245)",
          boxShadow: "none",
          p: "0px 20px",
        }}
      >
        <Toolbar>
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage
              id={"STANDARD.NEW"}
              defaultMessage={"STANDARD.NEW"}
            />
          </Typography>
          <Button
            form={"setting_form"}
            autoFocus
            type={"submit"}
            sx={{
              backgroundColor: "#0D99FF",
              borderRadius: 2,
              color: "#ffffff",
            }}
          >
            <FormattedMessage
              id={"DIALOGUE.SAVE"}
              defaultMessage={"DIALOGUE.SAVE"}
            />
          </Button>
        </Toolbar>
      </Box>
      <DialogContent>
        <form
          id={"setting_form"}
          onSubmit={(e) => form.handleSubmit(e, handleSave)}
        >
          <Grid container spacing={2} mb={2}>
            {system_languages &&
              system_languages.map((system_language) => {
                return (
                  <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                    {loaded ? (
                      <Controls.Input
                        name={`title_${system_language.short_code}`}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          if (!form.values.title) {
                            form.values.title = {};
                          }

                          form.values.title[system_language.short_code] =
                            event.target.value;
                          event.preventDefault();
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {system_language.short_code.toUpperCase()}
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          name={`title_${system_language.short_code}`}
                          label={intl.formatMessage({
                            id: "STANDARD.TITLE",
                          })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                );
              })}
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded ? (
                <>
                  <Controls.Input
                    name={"color"}
                    label={intl.formatMessage({ id: "STANDARD.COLOR" })}
                    inputRef={colorRef}
                    defaultValue={form.values.color}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.color = event.target.value;

                      event.preventDefault();
                    }}
                  />
                  <Grid container>
                    <Palette
                      onPick={(color) => {
                        if (color) {
                          form.values.color = color;
                          if (colorRef.current) colorRef.current.value = color;
                        } else {
                          form.values.color = "";
                          if (colorRef.current) colorRef.current.value = "";
                        }
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"color"}
                    label={intl.formatMessage({ id: "STANDARD.COLOR" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded ? (
                <Controls.Select
                  name={"status"}
                  formId={"status"}
                  options={[
                    {
                      id: "1",
                      title: intl.formatMessage({ id: "STANDARD.ACTIVE" }),
                    },
                    {
                      id: "0",
                      title: intl.formatMessage({
                        id: "STANDARD.DEACTIVE",
                      }),
                    },
                  ]}
                  label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.active = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Select
                    name={"status"}
                    options={[]}
                    label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded ? (
                <Controls.Select
                  name={"feedback"}
                  formId={"feedback"}
                  options={[
                    {
                      id: "1",
                      title: intl.formatMessage({ id: "STANDARD.ACTIVE" }),
                    },
                    {
                      id: "0",
                      title: intl.formatMessage({
                        id: "STANDARD.DEACTIVE",
                      }),
                    },
                  ]}
                  label={intl.formatMessage({
                    id: "STANDARD.FEEDBACK_ACTIVE",
                    defaultMessage: "Feedback active",
                  })}
                  onChange={(event) => {
                    if (!event.target.value) return;

                    form.values.feedback_active = event.target.value;
                  }}
                />
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Select
                    name={"status"}
                    options={[]}
                    label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default TimeTrackTypeStore;
