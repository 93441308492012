import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Skeleton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useForm } from "app/hooks/useForm";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "app/widgets/uncontrolled";
import Snackbar from "app/widgets/Snackbar";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { caseService, contractService, userService } from "app/services";
import { IRole, IUser } from "../../../interfaces";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import { ICase } from "../../../services/case.service";
import { DatePicker, Select } from "antd";

interface IFormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  content: string;
  for: string;
  employee: string;
  template: string;
  case: string;
  start_date: string;
  end_date: string;
  subject: string;
};

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

const ContractTemplateSend: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const classes = useStyles();
  const { roles } = useSelector((state: RootState) => state.permission);
  const [needleRole, setNeedleRole] = useState<string>("");
  const [users, setUsers] = useState<IUser[]>();
  const [usersSearch, setUserSearch] = useState<string>("");
  const [cases, setCases] = useState<ICase[]>();
  const getUsers = () => {
    if (needleRole) {
      userService
        .getAll([
          {
            name: "role",
            value: needleRole,
          },
          {
            name: "pagination",
            value: "1", //TODO change it to false
          },
          {
            name: "skip",
            value: 0,
          },
          {
            name: "limit",
            value: 10,
          },
        ])
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          const { data: users } = data;

          setUsers(users);
        });
    }
  };

  useEffect(() => {
    if (loaded) {
      const value = roles.find((r: IRole) => r.slug === "employee")?._id;
      if (!value) return;

      setNeedleRole(value);
    }
  }, [loaded]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  useEffect(() => {
    if (needleRole) getUsers();
  }, [props._id, needleRole]);

  useEffect(() => {
    if (!usersSearch?.length) return;
    form.values.employee = usersSearch;
  }, [usersSearch]);

  useEffect(() => {
    caseService
      .getAll([
        {
          name: "pagination",
          value: "0",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: cases } = data;
        setCases(cases);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  console.log("users", users);
  const handleSave = () => {
    form.values.template = props._id;
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    contractService
      .store(validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));
        props.setDialogue(false);
        form.clear();
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };
  const timeOut = useRef<any>();

  const sendQuery = (value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      setUserSearch(value);
    }, 500);
  };

  useEffect(() => {
    if (usersSearch.length > 0) {
      let queries = [
        {
          name: "pagination",
          value: "1",
        },
        {
          name: "role",
          value: needleRole,
        },
        {
          name: "skip",
          value: 0,
        },
        {
          name: "limit",
          value: 10,
        },
        {
          name: "keyword",
          value: usersSearch,
        },
      ];

      userService
        .getAll(queries)
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          const { data: users } = data;

          setUsers(users);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [usersSearch]);

  return (
    <>
      {
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"xs"}
          onClose={handleClose}
          TransitionComponent={Transition}
          sx={{
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"CONTRACT.SEND"}
                  defaultMessage={"CONTRACT.SEND"}
                />
              </Typography>
              <Button
                autoFocus
                color="inherit"
                type={"submit"}
                form={"contract_template_form"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SEND"}
                  defaultMessage={"DIALOGUE.SEND"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"contract_template_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"CASE.INFORMATION.EMPLOYEE"}
                          defaultMessage={"CASE.INFORMATION.EMPLOYEE"}
                        />
                      </Typography>
                      <Select
                        placeholder={""}
                        showSearch
                        onChange={(value: string) => {
                          // setUserSearch(value);
                          form.values.employee = value;
                        }}
                        onSearch={(value) => {
                          sendQuery(value);
                        }}
                        filterOption={false}
                        style={{ width: "100%" }}
                        allowClear={true}
                        options={
                          users &&
                          users.map((user) => {
                            return {
                              value: user._id.toString(),
                              label: user.firstname + " " + user.lastname,
                            };
                          })
                        }
                        className={classes.antSelect}
                        onClear={() => {
                          sendQuery(" ");
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"employee"}
                        options={[]}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"CASE.INFORMATION.CASE"}
                          defaultMessage={"CASE.INFORMATION.CASE"}
                        />
                      </Typography>
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        onChange={(e, value: any, reason: string) => {
                          if (reason === "clear") {
                            form.values.case = "";
                            return;
                          } else {
                            form.values.case = value?.id;
                          }
                        }}
                        options={
                          cases
                            ? cases.map((_case) => {
                                return {
                                  id: _case._id,
                                  label:
                                    _case.key +
                                      " " +
                                      _case.users.find(
                                        (user) => user.role?.slug === "citizen"
                                      )?.fullname ?? null,
                                };
                              })
                            : []
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="" />
                        )}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"case"}
                        options={[]}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"CASE.TIMETRACK.STARTDATE"}
                          defaultMessage={"CASE.TIMETRACK.STARTDATE"}
                        />
                      </Typography>
                      <DatePicker
                        className={classes.datePicker}
                        dropdownClassName={classes.antDropdown}
                        onChange={(value, dateString) => {
                          form.values.start_date = dateString;
                        }}
                        placeholder={""}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"start_date"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"CASE.TIMETRACK.ENDDATE"}
                          defaultMessage={"CASE.TIMETRACK.ENDDATE"}
                        />
                      </Typography>
                      <DatePicker
                        className={classes.datePicker}
                        dropdownClassName={classes.antDropdown}
                        onChange={(value, dateString) => {
                          form.values.end_date = dateString;
                        }}
                        placeholder={""}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"end_date"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.input}>
                      <Typography variant={"body1"}>
                        <FormattedMessage
                          id={"STANDARD.SUBJECT"}
                          defaultMessage={"STANDARD.SUBJECT"}
                        />
                      </Typography>
                      <Controls.Input
                        name={"subject"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.subject = event.target.value;
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"subject"}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ContractTemplateSend;
