import { API_ROUTES, getRoute } from "../config/apiRoutes";
import {
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
  IUrlQuery,
} from "../interfaces";
import { generateFormData, queryToString } from "../utils/http";
import { http } from "../lib/http";

const GRAMMAR = API_ROUTES.grammar;
const GRAMMAR_HIGH_LEVEL = API_ROUTES.grammar_high;

export interface IModuleService {
  grammar(data: any): Promise<any | IRestApiError>;
  highlevelgrammar(data: any): Promise<any | IRestApiError>;
}

const aiservice: IModuleService = {
  async grammar(data: any) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(GRAMMAR), "post", formData);
  },
  async highlevelgrammar(data: any) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(GRAMMAR_HIGH_LEVEL), "post", formData);
  },
};

export { aiservice };
