import React, { FC } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Box } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import ChartCard from "./ChartCard";
import NoData from "../partials/NoData";
import { useIntl } from "react-intl";

interface IProps {
  onClick?: Function;
  value1: number;
  value2: number;
  currency: string;
}

const date = new Date();
const month = date.toLocaleString("default", { month: "long" });

const BudgetStatisticsChart: FC<IProps> = (props) => {
  const intl = useIntl();
  const onClick = () => {
    props.onClick && props.onClick();
  };
  const series = [props.value1, props.value2 - props.value1];
  let color: string;
  const _percent = +((props.value1 * 100) / props.value2).toFixed(0);

  if (_percent <= 33) {
    color = "#ffcc00";
  } else if (_percent > 34 && _percent <= 66) {
    color = "#0D99FF";
  } else {
    color = "#2FFF93";
  }

  return (
    <ChartCard
      title={intl.formatMessage({
        id: "STANDARD.BUDGET",
        defaultMessage: "Budget",
      })}
      ratio={"100%"}
      body={
        <Box
          sx={{
            height: "calc(100% + 60px - 31%)",
            width: "66%",
            paddingBottom: "4%",
            display: "flex",
            cursor: "pointer",
          }}
          onClick={onClick}
        >
          <ReactApexChart
            options={{
              grid: {
                padding: {
                  left: 0,
                  right: 0,
                  bottom: 0,
                  top: 0,
                },
              },
              legend: {
                show: false,
              },
              dataLabels: {
                enabled: false,
              },
              tooltip: {
                followCursor: true,
                fillSeriesColor: false,
                theme: "light",
                y: {
                  formatter: function (value) {
                    return value + " " + props.currency;
                  },
                },
              },
              labels: [
                intl.formatMessage({
                  id: "STANDARD.USED",
                  defaultMessage: "Used",
                }),
                intl.formatMessage({
                  id: "STANDARD.REMAIN",
                  defaultMessage: "Remain",
                }),
              ],
              colors: [color, "#F4F4F4"],
              stroke: {
                width: 2,
                lineCap: "round",
              },
              plotOptions: {
                pie: {
                  donut: {
                    size: "75%",
                    labels: {
                      show: true,
                      value: {
                        show: true,
                        fontSize: "20px",
                        fontWeight: 600,
                      },
                      total: {
                        show: true,
                        showAlways: true,
                        fontSize: "13px",
                        fontFamily: "Poppins",
                        formatter: function (value) {
                          return (
                            props.value2 - props.value1 + " " + props.currency
                          );
                        },
                        label: month,
                      },
                    },
                  },
                },
              },
            }}
            series={series}
            type="donut"
            height={"100%"}
            width={"100%"}
          />
        </Box>
      }
      onClick={onClick}
    />
  );
};

export default BudgetStatisticsChart;
