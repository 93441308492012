import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "../../partials/layout/DataTable";
import { Avatar, Grid, IconButton, Typography } from "@mui/material";
import { userService } from "app/services";
import { ISecureUser, IUser } from "../../interfaces";
import { useTable } from "../../hooks/useTable";
import { GridTable } from "../../partials/layout/GridTable";
import UserStore from "./store";
import { useIntl } from "react-intl";
import {
  hasPermission,
  PermissionsGate,
} from "../../permission/PermissionsGate";
import { usePermissions } from "app/hooks/useRole";
import UserFilter from "./filter";
import ConfirmDialog from "../../partials/dialogues/confirmDialogue";
import DeleteIcon from "../../partials/icons/DeleteIcon";
import EditIcon from "../../partials/icons/EditIcon";
import ReasonModal from "./removeReason";
import Snackbar from "../../widgets/Snackbar";
import RecoverIcon from "app/partials/icons/RecoverIcon";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import UserAvatar from "../../partials/layout/UserAvatar";

type Props = {
  lang: string;
  online_users: Array<ISecureUser>;
  settings_loading: boolean;
};

const Users = (props: Props) => {
  const intl = useIntl();
  const history = useHistory();
  const { data, xhrLoading, refresh, queries, updateQuery, setQuery, remove } =
    useTable<IUser>({
      fetch: userService.getAll,
      delete: userService.delete,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });
  const tableRef = useRef();
  const permissions = usePermissions();
  const [init, setInit] = useState<boolean>(false);
  const { user: me } = useSelector(({ auth }: RootState) => auth);

  const [quickCardsCount, setQuickCardsCount] = useState<{
    former: number;
    active_employees: number;
    active_citizens: number;
    active_partners: number;
  }>();

  useEffect(() => {
    userService.getQuickCardsCount().then((data) => {
      if ("error" in data) {
        throw new Error(data.error.message);
      }

      const { data: counts } = data;

      setQuickCardsCount(counts);
    });
  }, [xhrLoading]);

  const { online_users } = useSelector((state: RootState) => state.user);

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      align: "center",
    },
    {
      headerName: intl.formatMessage({ id: "AUTH.INPUT.USERNAME" }),
      field: "username",
      renderCell: (params) => {
        return (
          <Grid container spacing={1}>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <UserAvatar user={params.row} />
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant={"inherit"}>
                {params.row?.firstname + " " + params.row?.lastname}
              </Typography>
            </Grid>
          </Grid>
        );
      },
      minWidth: 250,
    },
    {
      headerName: intl.formatMessage({ id: "CASE.INFORMATION.EMAIL" }),
      field: "email",
      valueGetter: (params) => {
        return params.row?.email;
      },
      minWidth: 220,
    },
    {
      headerName: intl.formatMessage({ id: "USERS.PHONENUMBER" }),
      field: "phone",
      valueGetter: (params) => {
        return params.row?.phone;
      },
      minWidth: 190,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.STATUS" }),
      field: "status",
      renderCell: (params) => {
        let status = online_users?.some((e) => e._id === params.row?._id)
          ? "Online"
          : "Offline";
        let color = status === "Online" ? "success.main" : "error.main";

        return (
          <div>
            <Typography sx={{ color: color, fontSize: 13 }} variant={"inherit"}>
              {status}
            </Typography>
          </div>
        );
      },
      minWidth: 120,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <PermissionsGate section={"users"} scope={"read"}>
              <IconButton
                onClick={() => show(params.row?._id)}
                data-cy-class={"user_show"}
                data-cy-id={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>

            {params.row?.deleted_at ? (
              <IconButton
                onClick={() => handleRestore(params.row?._id)}
                data-cy-class={"user_recover"}
                data-cy-id={params.row?._id}
              >
                <RecoverIcon />
              </IconButton>
            ) : (
              <PermissionsGate section={"users"} scope={"delete"}>
                <IconButton
                  onClick={() => handleClick(params.row?._id)}
                  data-cy-class={"user_delete"}
                  data-cy-id={params.row?._id}
                >
                  <DeleteIcon />
                </IconButton>
              </PermissionsGate>
            )}
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 100,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const show = (id: string) => {
    const url = `/users/${id}`;
    // window.open(url);
    history.push(url);
  };

  const [confirmDialogue, setConfirmDialogue] = useState<boolean>(false);
  const [confirmId, setConfirmId] = useState<string | null>(null);
  const [reasonDialogue, setReasonDialogue] = useState<boolean>(false);

  const handleClick = (_id: string) => {
    setReasonDialogue(true);
    setConfirmId(_id);
  };

  const resetCardQuery = (
    isActive: boolean,
    name: "former" | "active_employees" | "active_citizens" | "active_partners"
  ) => {
    let _queries = queries?.filter((query) => {
      return (
        query.name != "former" &&
        query.name != "active_employees" &&
        query.name != "active_citizens" &&
        query.name != "active_partners"
      );
    });

    if (!_queries) return;

    if (isActive && queries && _queries) {
      let __queries = _queries.filter((q) => q.name !== name);
      setQuery(__queries);
    } else {
      setQuery([
        ..._queries,
        {
          name: name,
          value: "1",
        },
      ]);
    }
  };

  const handleReason = (
    validated: { reason: string },
    callback: () => void
  ) => {
    if (confirmId) {
      userService
        .addDeleteReason(confirmId, validated)
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          } else {
            setReasonDialogue(false);

            Snackbar.success(
              intl.formatMessage({ id: "SNACKBAR.REASON_ADDED" })
            );
            setConfirmDialogue(true);
            callback();
          }
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  };

  const handleRestore = (_id: string) => {
    userService
      .restore(_id, [{ name: "per_page", value: 5 }])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        } else {
          Snackbar.success(intl.formatMessage({ id: "SNACKBAR.RECOVERED" }));
          refresh();
        }
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  return (
    <>
      <PermissionsGate section={"users"} scope={"delete"}>
        <>
          {reasonDialogue ? (
            <>
              <ReasonModal
                open={reasonDialogue}
                setDialogue={() => setReasonDialogue(false)}
                submit={handleReason}
              />
            </>
          ) : (
            <></>
          )}
          <ConfirmDialog
            title="Remove this user?"
            open={confirmDialogue}
            setOpen={() => setConfirmDialogue(false)}
            onConfirm={() => {
              console.log("confirmId", confirmId);
              if (confirmId) remove(confirmId);
              // setReasonDialogue(true);
            }}
          >
            Are you sure you want to delete this user?
          </ConfirmDialog>
        </>
      </PermissionsGate>
      <PermissionsGate section={"users"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          query={(queries) => updateQuery(queries)}
          search={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "users", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <UserStore
                        open={props.open}
                        setDialogue={props.close}
                        onDone={refresh}
                      />
                    );
                  }
                : undefined,
            quickCards: [
              {
                title: intl.formatMessage({
                  id: "ACTIVE_EMPLOYEES",
                }),
                onClick: (isActive: boolean) =>
                  resetCardQuery(isActive, "active_employees"),
                icon: "fi-sr-user",
                value:
                  quickCardsCount && (quickCardsCount.active_employees ?? 0),
              },
              {
                title: intl.formatMessage({
                  id: "ACTIVE_CITIZENS",
                }),
                onClick: (isActive: boolean) =>
                  resetCardQuery(isActive, "active_citizens"),
                icon: "fi-sr-user",
                value:
                  quickCardsCount && (quickCardsCount.active_citizens ?? 0),
              },
              {
                title: intl.formatMessage({
                  id: "ACTIVE_PARTNERS",
                }),
                onClick: (isActive: boolean) =>
                  resetCardQuery(isActive, "active_partners"),
                icon: "fi-sr-user",
                value:
                  quickCardsCount && (quickCardsCount.active_partners ?? 0),
              },
              {
                title: intl.formatMessage({ id: "FORMERS" }),
                onClick: (isActive: boolean) =>
                  resetCardQuery(isActive, "former"),
                icon: "fi-sr-user",
                value: quickCardsCount && (quickCardsCount.former ?? 0),
              },
            ],
            filters:
              me?.role.slug === "citizen"
                ? (props: { show: boolean }) => {
                    return (
                      <UserFilter
                        show={props.show}
                        updateQuery={updateQuery}
                        queries={queries}
                        setQuery={setQuery}
                      />
                    );
                  }
                : undefined,
          }}
        />
      </PermissionsGate>
    </>
  );
};

export default Users;
