import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Controls from "app/widgets/uncontrolled";
import moment from "moment";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "app/hooks/useForm";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { caseService, ICase } from "app/services/case.service";
import { IUser } from "app/interfaces";
import { TransitionProps } from "@mui/material/transitions";
import Snackbar from "app/widgets/Snackbar";
import { aiservice, todoService, userService } from "app/services";
import { ITodo } from "app/services/todo.service";
import { FormattedMessage, useIntl } from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/core";
import SelectUsers, { SelectedUsers } from "../../SelectUsers";
import { DatePicker, Select, Space, Tooltip } from "antd";
import Autocomplete from "@mui/material/Autocomplete";
import UserAvatar from "../../layout/UserAvatar";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { INote } from "../../../services/note.service";
import EditIcon from "../../icons/EditIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../confirmDialogue";
import Attachments from "../../../pages/Cases/store/parts/attachments";
import AttachmentsInfo, {
  getFileExtensionIcon,
} from "../../../pages/Cases/show/parts/attachmentsInfo";
import { getAccessToken, getBusiness } from "../../../lib/http";
// import SpeechRecognition, {
//   useSpeechRecognition,
// } from "react-speech-recognition";

interface IFormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone?: Function;
  case_needed: boolean;
  show_note?: boolean;
  save?: Function;
  _todo?: ITodo | null;
}

export type FormStateValues = {
  type: string;
  title: string | null;
  category: string;
  description: string;
  description_org: string;
  case: string;
  lead: string;
  users: Array<string>;
  date: string;
  start_time: string;
  deadline_date: string;
  end_time: string;
  status: string;
  note: {
    title: string;
    description: string;
    files: string[];
  };
};

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  dropzone: {
    "& .container": {
      paddingRight: "0!important",
      paddingLeft: "0!important",
      "& div": {
        padding: "70px!important",
        borderColor: "#E6E6E6!important",
        borderRadius: 2,
        backgroundColor: "#E6E6E6",
        "& p": {
          marginBottom: "0px!important",
        },
      },
      "& aside": {
        display: "none",
      },
    },
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
  iconButtonAdd: {
    "&:hover": {
      // backgroundColor: "#556EE6!important",
    },
  },
  listItem: {
    "& .MuiListItemButton-root": {
      "&:hover": {
        backgroundColor: "#fff!important",
      },
      "&:active": {
        backgroundColor: "#fff!important",
      },
    },
  },
}));

const TodoShowDialogue: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const form = useForm<FormStateValues>();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const { todo_types } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { todo_categories } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { todo_statuses } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const [cases, setCases] = useState<Array<ICase>>();
  const [notes, setNotes] = useState<Array<INote>>([]);
  const [note, setNote] = useState<INote | undefined>(undefined);
  const [usersDialog, setUsersDialog] = useState<boolean | null>(false);
  const [selectedUsers, setSelectedUsers] = useState<Array<SelectedUsers>>([]);
  const [status, setStatus] = useState<boolean>(true);
  const [noteAddModal, setNoteAddModal] = useState<boolean>(false);
  const [noteEditModal, setNoteEditModal] = useState<string>("");
  const [confirmId, setConfirmId] = useState<string | null>(null);
  const classes = useStyles();
  const timeOut = useRef<any>();
  const [leads, setLeads] = useState<IUser[]>([]);
  const [userSearch, setUserSearch] = useState<string>("");
  const [noteTitle, setNoteTitle] = useState<string>("");
  const [noteDesc, setNoteDesc] = useState<string>("");
  const [files, setFiles] = useState<any>([]);
  const [editable, setEditable] = useState<boolean>(false);
  const [orgDesc, setOrgDesc] = useState<string>("");
  const [noteEditable, setNoteEditable] = useState<boolean>(false);
  const [noteOrgDesc, setNoteOrgDesc] = useState<string>("");
  const [noteOrgDescAfter, setNoteOrgDescAfter] = useState<string>("");
  const [draftDesc, setDraftDesc] = useState<string>("");
  const orgDescRef = useRef<HTMLInputElement>(null);
  const { user } = useSelector(({ auth }: RootState) => auth);

  const sendQuery = (value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      setUserSearch(value);
    }, 500);
  };

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
      {
        name: "keyword",
        value: userSearch,
      },
    ];

    userService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setLeads(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [userSearch]);

  useEffect(() => {
    let queries = [
      {
        name: "pagination",
        value: "1",
      },
      {
        name: "skip",
        value: 0,
      },
      {
        name: "limit",
        value: 10,
      },
    ];

    userService
      .getAll(queries)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: users } = data;

        setLeads(users);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  const handleClose = () => {
    props.setDialogue(false);
    handleStop("todo");
    handleReset("todo");
    form.values.description = "";
    form.clear();
  };

  useEffect(() => {
    caseService
      .getAll([
        {
          name: "pagination",
          value: "0",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: cases } = data;
        setCases(cases);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  const save = () => {
    if (props.save) {
      form.values.description_org = orgDesc;
      props.save(form.validate(), () => {
        form.clear();
      });
    }
  };

  useEffect(() => {
    if (props._todo) {
      form.values.type = props._todo?.type?._id;
      form.values.title = props._todo?.title;
      form.values.category = props._todo?.category?._id;
      form.values.description = props._todo?.description;
      form.values.description_org = props._todo?.description_org;
      form.values.case = props._todo?.case?._id;
      form.values.lead = props._todo?.lead?._id;
      form.values.users = props._todo?.users?.map((user) => user?._id);
      form.values.date = (
        moment.unix(props._todo.date).utc().valueOf() / 1000
      ).toString();
      form.values.deadline_date = (
        moment.unix(props._todo.deadline_date).utc().valueOf() / 1000
      ).toString();
      form.values.status = props._todo?.status?._id;
      setSelectedUsers(
        props._todo.users?.map((user) => {
          return {
            _id: user?._id,
            firstname: user?.firstname,
            lastname: user?.lastname,
            role: user?.role,
            file: user?.file ?? undefined,
          };
        })
      );

      setStatusField(props._todo?.type?._id);
      if (props._todo.description_org) {
        setEditable(true);
        setOrgDesc(props._todo.description_org);
      }

      if (orgDescRef.current) {
        orgDescRef.current.value = props._todo.description_org;
      }

      setDraftDesc(props._todo.description);
      if (descRef.current) {
        descRef.current.value = props._todo.description;
      }
    }
  }, [props._todo]);

  const getNotes = (id: string) => {
    todoService.getNotes(id).then((data) => {
      if ("error" in data) {
        throw new Error(data.error.message);
      }

      const { data: notes } = data;

      setNotes(notes);
    });
  };

  useEffect(() => {
    if (props._todo) getNotes(props._todo._id);
  }, [props._todo]);

  useEffect(() => {
    form.values.users = selectedUsers.map((selectedUser) => selectedUser._id);
  }, [selectedUsers]);

  useEffect(() => {
    if (props._todo?._id && noteEditModal) {
      todoService.getNote(props._todo._id, noteEditModal).then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }
        const { data: note } = data;

        setNote(note);
      });
    }
  }, [noteEditModal]);

  const completedTodoStatus = todo_statuses?.find(
    (todo_status) => todo_status.slug === "completed"
  )?._id;

  const setStatusField = (type: string) => {
    const slug =
      type && todo_types?.find((todo_status) => todo_status._id === type)?.slug;

    if (slug === "event") {
      setStatus(false);
    } else {
      setStatus(true);
    }
  };

  const handleNoteSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    if (props._todo?._id) {
      todoService
        .addNote(props._todo._id, {
          title: noteTitle,
          description: noteDesc,
          description_org: noteOrgDesc,
          users: [],
          files: files?.file_ids?.map((file: any) => file._id),
        })
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

          setNoteAddModal(false);
          if (props._todo?._id) getNotes(props._todo._id);
          setNoteTitle("");
          setNoteDesc("");
          setNoteOrgDesc("");
          setNoteEditable(false);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  };

  const handleUpdateNote = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    if (props._todo?._id) {
      todoService
        .updateNote(props._todo._id, noteEditModal, {
          title: noteTitle,
          description: noteDesc,
          description_org: noteOrgDesc,
          users: [],
          files: files?.attachments?.map((file: any) => file._id),
        })
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

          setNoteEditModal("");
          if (props._todo?._id) getNotes(props._todo._id);
          setNote(undefined);
          setNoteTitle("");
          setNoteDesc("");
          setNoteOrgDesc("");
          setNoteEditable(false);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  };

  const handleDeleteNote = (todo: string, id: string) => {
    todoService
      .deleteNote(todo, id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.DELETED" }));

        setConfirmId("");
        if (props._todo?._id) getNotes(props._todo._id);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const handleFile = async (id: string, name: string) => {
    const token = getAccessToken(); // Replace with your actual token
    const response = await fetch(`/api/v1/documents/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Business: getBusiness(),
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleGrammar = () => {
    if (form.values.description.length < 1) {
      Snackbar.error(
        intl.formatMessage({
          id: "SNACKBAR.TEXTERROR",
          defaultMessage: "Please enter a text",
        })
      );
      return;
    }

    aiservice.grammar({ text: form.values.description }).then((data) => {
      setEditable(true);
      if (data.corrected_text) {
        setOrgDesc(data.corrected_text);
        if (orgDescRef.current) {
          orgDescRef.current.value = data.corrected_text;
        }
      }
    });
  };

  const handleHighLevelGrammar = () => {
    if (form.values.description.length < 1) {
      Snackbar.error(
        intl.formatMessage({
          id: "SNACKBAR.TEXTERROR",
          defaultMessage: "Please enter a text",
        })
      );
      return;
    }

    aiservice
      .highlevelgrammar({
        text: form.values.description,
        focus_area: props._todo?.case?.focus_area?.title[intl.locale] ?? "",
        focus_type: props._todo?.case?.focus_type?.title[intl.locale] ?? "",
      })
      .then((data) => {
        setEditable(true);

        if (data.corrected_text) setOrgDesc(data.corrected_text);
      });
  };

  const handleNoteGrammar = () => {
    if (noteDesc.length < 1) {
      Snackbar.error(
        intl.formatMessage({
          id: "SNACKBAR.TEXTERROR",
          defaultMessage: "Please enter a text",
        })
      );
      return;
    }

    aiservice.grammar({ text: noteDesc }).then((data) => {
      setNoteEditable(true);
      if (data.corrected_text) setNoteOrgDesc(data.corrected_text);
    });
  };

  const handleNoteHighLevelGrammar = () => {
    if (noteDesc.length < 1) {
      Snackbar.error(
        intl.formatMessage({
          id: "SNACKBAR.TEXTERROR",
          defaultMessage: "Please enter a text",
        })
      );
      return;
    }

    aiservice
      .highlevelgrammar({
        text: noteDesc,
        focus_area: props._todo?.case?.focus_area?.title[intl.locale] ?? "",
        focus_type: props._todo?.case?.focus_type?.title[intl.locale] ?? "",
      })
      .then((data) => {
        setNoteEditable(true);

        if (data.corrected_text) setNoteOrgDesc(data.corrected_text);
      });
  };

  useEffect(() => {
    setNoteOrgDescAfter(noteOrgDesc);
  }, [noteOrgDesc]);

  const languageCodes: any = {
    en: "en-US",
    ar: "ar-AR",
    zh: "zh-CN",
    cs: "cs-CZ",
    da: "da-DK",
    nl: "nl-NL",
    fi: "fi-FI",
    fr: "fr-FR",
    de: "de-DE",
    el: "el-GR",
    hi: "hi-IN",
    hu: "hu-HU",
    it: "it-IT",
    ja: "ja-JP",
    ko: "ko-KR",
    pl: "pl-PL",
    pt: "pt-BR",
    ru: "ru-RU",
    es: "es-ES",
    sv: "sv-SE",
    tr: "tr-TR",
    vi: "vi-VN",
    az: "az-AZ",
  };

  const current = languageCodes[intl.locale] || languageCodes.en;
  const [isListening, setIsListening] = useState(false);
  const [isNoteListening, setIsNoteListening] = useState(false);
  const lastProcessedTranscriptShow = useRef("");
  const lastNoteProcessedTranscriptShow = useRef("");
  const recognitionTodo = useRef<SpeechRecognition | null>(null);
  const recognitionNote = useRef<SpeechRecognition | null>(null);
  const [transcript, setTranscript] = useState("");
  const [noteTranscript, setNoteTranscript] = useState("");
  const descRef = useRef<HTMLInputElement>(null);
  const noteDescRef = useRef<HTMLInputElement>(null);
  const noteShowDescRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (note) {
      setNoteTitle(note.title);
      setNoteDesc(note.description);
      setFiles(note.documents);
      if (noteShowDescRef.current) {
        noteShowDescRef.current.value = note.description;
      }
      if (note.description_org) {
        setNoteEditable(true);
        setNoteOrgDesc(note.description_org);
      }
    }
  }, [note]);

  useEffect(() => {
    if (noteShowDescRef.current) noteShowDescRef.current.value = noteDesc;
  }, [noteDesc]);

  useEffect(() => {
    if (
      !("SpeechRecognition" in window || "webkitSpeechRecognition" in window)
    ) {
      Snackbar.error("Your browser does not support speech recognition.");
      return;
    }

    // Setup recognition for Todo
    recognitionTodo.current = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition)();
    recognitionTodo.current.continuous = true;
    recognitionTodo.current.interimResults = false;
    recognitionTodo.current.lang = current;

    recognitionTodo.current.onresult = (event) => {
      let combinedTranscript = "";
      for (let i = 0; i < event.results.length; i++) {
        combinedTranscript += event.results[i][0].transcript;
      }
      setTranscript(combinedTranscript);
    };

    recognitionTodo.current.onerror = (event) => {
      Snackbar.error(`Todo Description Recognition Error: ${event.error}`);
    };

    // Setup recognition for Note
    recognitionNote.current = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition)();
    recognitionNote.current.continuous = true;
    recognitionNote.current.interimResults = false;
    recognitionNote.current.lang = current;

    recognitionNote.current.onresult = (event) => {
      let combinedTranscript = "";
      for (let i = 0; i < event.results.length; i++) {
        combinedTranscript += event.results[i][0].transcript;
      }
      setNoteTranscript(combinedTranscript);
    };

    recognitionNote.current.onerror = (event) => {
      Snackbar.error(`Note Description Recognition Error: ${event.error}`);
    };

    // Cleanup on unmount
    return () => {
      if (recognitionTodo.current) {
        recognitionTodo.current.stop();
        recognitionTodo.current = null;
      }
      if (recognitionNote.current) {
        recognitionNote.current.stop();
        recognitionNote.current = null;
      }
    };
  }, [current]);

  const handleStart = (type: string) => {
    if (type === "todo" && recognitionTodo.current) {
      recognitionTodo.current.start();
      lastProcessedTranscriptShow.current = "";
      setTranscript("");
      setIsListening(true);
    } else if (type === "note" && recognitionNote.current) {
      recognitionNote.current.start();
      lastNoteProcessedTranscriptShow.current = "";
      setNoteTranscript("");
      setIsNoteListening(true);
    }
  };

  const handleStop = (type: string) => {
    if (type === "todo" && recognitionTodo.current) {
      recognitionTodo.current.stop();
      setIsListening(false);
    } else if (type === "note" && recognitionNote.current) {
      recognitionNote.current.stop();
      setIsNoteListening(false);
    }
  };

  const handleReset = (type: string) => {
    if (type === "todo") {
      setOrgDesc("");
      setEditable(false);
      setTranscript("");
      setDraftDesc("");
      if (orgDescRef.current) orgDescRef.current.value = "";
      if (descRef.current) descRef.current.value = "";
      lastProcessedTranscriptShow.current = "";
      form.values.description = "";
    } else {
      setNoteEditable(false);
      setNoteTranscript("");
      setNoteOrgDesc("");
      setNoteDesc("");
      lastNoteProcessedTranscriptShow.current = "";
      if (noteDescRef.current) noteDescRef.current.value = "";
      if (noteShowDescRef.current) noteShowDescRef.current.value = "";
    }
  };

  useEffect(() => {
    if (transcript.length > 0) {
      const newText = transcript
        .replace(lastProcessedTranscriptShow.current, "")
        .trim();
      if (newText) {
        if (descRef.current) {
          descRef.current.value = descRef.current.value + " " + newText;
          form.values.description = descRef.current.value;
        }

        setDraftDesc((prevDescription) =>
          `${prevDescription} ${newText}`.trim()
        );
        lastProcessedTranscriptShow.current = transcript;
      }
    }
  }, [transcript]);

  useEffect(() => {
    if (noteTranscript.length > 0) {
      const newText = noteTranscript
        .replace(lastNoteProcessedTranscriptShow.current, "")
        .trim();
      if (newText) {
        if (noteDescRef.current) {
          noteDescRef.current.value = noteDescRef.current.value + " " + newText;
          setNoteDesc(noteDescRef.current.value);
        }
        if (noteShowDescRef.current) {
          noteShowDescRef.current.value =
            noteShowDescRef.current.value + " " + newText;
          setNoteDesc(noteShowDescRef.current.value);
        }

        lastNoteProcessedTranscriptShow.current = noteTranscript;
      }
    }
  }, [noteTranscript]);

  useEffect(() => {
    if (form.values.description) {
      if (descRef.current) {
        descRef.current.value = form.values.description;
      }
      setDraftDesc(form.values.description);
    }
  }, [form.values.description]);

  useEffect(() => {
    if (form.values.description_org) {
      if (orgDescRef.current) {
        orgDescRef.current.value = form.values.description_org;
      }
      setOrgDesc(form.values.description_org);
    }
  }, [form.values.description_org]);

  return (
    <>
      <SelectUsers
        open={Boolean(usersDialog)}
        Title={
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage id={"CASE.USERS"} defaultMessage={"CASE.USERS"} />
          </Typography>
        }
        handleClose={() => {
          setUsersDialog(null);
        }}
        handleSave={() => {}}
        onUserChange={(users) => {
          setSelectedUsers([...users]);
        }}
        users={selectedUsers}
      />
      <PerfectScrollbar
        options={perfectScrollbarOptions}
        style={{
          position: "relative",
          maxHeight: "710px",
        }}
      >
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"md"}
          TransitionComponent={Transition}
          sx={{
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#E0F2FF",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"DIALOGUE.EDIT"}
                  defaultMessage={"DIALOGUE.EDIT"}
                />
              </Typography>
              {!(
                user?.role?.slug !== "moderator" &&
                user?.role?.slug !== "super-admin"
              ) && (
                <>
                  <Button
                    onClick={() => {
                      handleGrammar();
                    }}
                    sx={{ marginRight: "10px" }}
                    variant="contained"
                  >
                    {intl.formatMessage({
                      id: "STANDARD.GRAMMAR_CORRECTION",
                      defaultMessage: "Grammar correction",
                    })}
                  </Button>
                  <Button
                    onClick={() => {
                      handleHighLevelGrammar();
                    }}
                    sx={{ marginRight: "10px" }}
                    variant="contained"
                  >
                    {intl.formatMessage({
                      id: "STANDARD.HIGHER_LEVEL",
                      defaultMessage: "Higher level correction",
                    })}
                  </Button>{" "}
                </>
              )}

              {props._todo &&
              props._todo.type?.slug === "todo" &&
              props._todo.status?.slug !== "completed" ? (
                <Button
                  color="success"
                  data-cy-class={"todo_update"}
                  data-cy-todo-id={props._todo?._id}
                  data-cy-case-id={props._id}
                  type={"submit"}
                  form={"todo_form"}
                  onClick={() => {
                    form.values.status = completedTodoStatus ?? "";
                  }}
                  sx={{
                    backgroundColor: "#2baa10",
                    borderRadius: 2,
                    color: "#ffffff",
                    marginRight: 2,
                  }}
                >
                  <FormattedMessage
                    id={"TODO.FINISH"}
                    defaultMessage={"TODO.FINISH"}
                  />
                </Button>
              ) : (
                <></>
              )}
              <Button
                color="inherit"
                data-cy-class={"todo_update"}
                data-cy-todo-id={props._todo?._id}
                data-cy-case-id={props._id}
                type={"submit"}
                form={"todo_form"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          {
            <DialogContent sx={{ p: "20px 44px" }}>
              <form
                id={"todo_form"}
                onSubmit={(e) => form.handleSubmit(e, save)}
              >
                <Grid container spacing={2} mb={2}>
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded && props._todo ? (
                      <Box className={classes.input}>
                        <Divider sx={{ mb: 1 }}>
                          <Chip
                            label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                            sx={{
                              backgroundColor: "#0D99FF",
                              color: "#ffffff",
                            }}
                          />
                        </Divider>
                        <Controls.Input
                          name={"title"}
                          label={""}
                          defaultValue={props._todo?.title}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.title = event.target.value;

                            event.preventDefault();
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          name={"title"}
                          label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    <Box>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({ id: "CASE.USERS" })}
                          sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                        />
                      </Divider>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        <FormattedMessage
                          id={"LABEL.SELECTUSER"}
                          defaultMessage={"LABEL.SELECTUSER"}
                        />
                      </Typography>
                      <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
                        {selectedUsers &&
                          selectedUsers.map((user) => {
                            return (
                              <Box sx={{ position: "relative" }}>
                                <UserAvatar user={user} />
                                <IconButton
                                  onClick={() => {
                                    setSelectedUsers(
                                      selectedUsers.filter(
                                        (s_user) => s_user._id !== user._id
                                      )
                                    );
                                  }}
                                  sx={{
                                    position: "absolute",
                                    top: "-5px",
                                    right: "-5px",
                                    padding: "5px",
                                    backgroundColor: "#EC3033",
                                    "&:hover": { backgroundColor: "#EC3033" },
                                  }}
                                >
                                  <CloseOutlinedIcon
                                    sx={{
                                      color: "#fff",
                                      width: 10,
                                      height: 10,
                                    }}
                                  />
                                </IconButton>
                              </Box>
                            );
                          })}
                        <Avatar sx={{ width: 44, height: 44 }}>
                          <Button
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "100%",
                              p: "14px!important",
                              backgroundColor: "#0D99FF",
                            }}
                            variant="contained"
                            onClick={() => {
                              setUsersDialog(true);
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20.75 10.75H13.25V3.25C13.25 2.55965 12.6904 2 12 2C11.3096 2 10.75 2.55965 10.75 3.25V10.75H3.25C2.55965 10.75 2 11.3096 2 12C2 12.6904 2.55965 13.25 3.25 13.25H10.75V20.75C10.75 21.4404 11.3096 22 12 22C12.6904 22 13.25 21.4404 13.25 20.75V13.25H20.75C21.4404 13.25 22 12.6904 22 12C22 11.3096 21.4404 10.75 20.75 10.75Z"
                                fill="white"
                              />
                            </svg>
                          </Button>
                        </Avatar>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mb={2}>
                  <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                    {loaded && props._todo ? (
                      <Box className={classes.input}>
                        <Divider sx={{ mb: 1 }}>
                          <Chip
                            label={intl.formatMessage({
                              id: "STANDARD.CASECATEGORY",
                              defaultMessage: "Case&Category",
                            })}
                            sx={{
                              backgroundColor: "#0D99FF",
                              color: "#ffffff",
                            }}
                          />
                        </Divider>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"STANDARD.CATEGORY"}
                            defaultMessage={"STANDARD.CATEGORY"}
                          />
                        </Typography>
                        <Controls.Select
                          name={"category"}
                          formId={"category_pick"}
                          options={todo_categories?.map((t) => {
                            return {
                              id: t._id.toString(),
                              title: t.title[intl.locale],
                            };
                          })}
                          defaultValue={props._todo?.category?._id}
                          label={""}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.category = event.target.value;
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Select
                          name={"category"}
                          options={[]}
                          label={intl.formatMessage({
                            id: "STANDARD.CATEGORY",
                          })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2} mb={2}>
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded && props._todo ? (
                      <Box className={classes.input}>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"STANDARD.TYPE"}
                            defaultMessage={"STANDARD.TYPE"}
                          />
                        </Typography>
                        <Controls.Select
                          name={"type"}
                          formId={"type_pick"}
                          options={todo_types?.map((t) => {
                            return {
                              id: t._id.toString(),
                              title: t.title[intl.locale],
                            };
                          })}
                          defaultValue={props._todo?.type?._id}
                          label={""}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.type = event.target.value;
                            setStatusField(event.target.value);
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Select
                          name={"type"}
                          options={[]}
                          onChange={() => {}}
                          label={intl.formatMessage({ id: "STANDARD.TYPE" })}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    {loaded && props._todo ? (
                      <Box className={classes.input}>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"CASE.INFORMATION.CASE"}
                            defaultMessage={"CASE.INFORMATION.CASE"}
                          />
                        </Typography>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          onChange={(e, value: any) => {
                            form.values.case = value?.id;
                          }}
                          defaultValue={
                            props._todo?.case && {
                              id: props._todo?.case?._id,
                              label:
                                props._todo?.case?.key +
                                ", " +
                                intl.formatMessage({
                                  id: "CASE.INFORMATION.CASE",
                                }) +
                                ", users, " +
                                props._todo?.case?.users?.map(
                                  (u) => u.firstname + " "
                                ),
                            }
                          }
                          options={
                            cases
                              ? cases.map((_case) => {
                                  return {
                                    id: _case._id,
                                    label:
                                      _case.key +
                                      ", " +
                                      intl.formatMessage({
                                        id: "CASE.INFORMATION.CASE",
                                      }) +
                                      ", users, " +
                                      _case.users.map(
                                        (u) =>
                                          u.firstname + " " + u.lastname + " "
                                      ),
                                  };
                                })
                              : []
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="" />
                          )}
                        />
                        {/*<Controls.Select*/}
                        {/*  name={"case"}*/}
                        {/*  formId={"case_pick"}*/}
                        {/*  options={*/}
                        {/*    cases &&*/}
                        {/*    cases.map((_case) => {*/}
                        {/*      return {*/}
                        {/*        id: _case._id.toString(),*/}
                        {/*        title: `${intl.formatMessage({*/}
                        {/*          id: "CASE.INFORMATION.CASE",*/}
                        {/*        })}: ${_case.key}; Users: ${_case.users.map(*/}
                        {/*          (u) => u.firstname*/}
                        {/*        )}`,*/}
                        {/*      };*/}
                        {/*    })*/}
                        {/*  }*/}
                        {/*  defaultValue={props._todo?.case?._id}*/}
                        {/*  label={""}*/}
                        {/*  onChange={(event) => {*/}
                        {/*    if (!event.target.value) return;*/}

                        {/*    form.values.case = event.target.value;*/}
                        {/*  }}*/}
                        {/*/>*/}
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Select
                          name={"case"}
                          options={[]}
                          label={intl.formatMessage({
                            id: "CASE.INFORMATION.CASE",
                          })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2} mb={2}>
                  {status && (
                    <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                      {loaded && props._todo ? (
                        <Box className={classes.input}>
                          <Typography variant={"body1"} sx={{ mb: 1 }}>
                            <FormattedMessage
                              id={"STANDARD.STATUS"}
                              defaultMessage={"STANDARD.STATUS"}
                            />
                          </Typography>
                          <Controls.Select
                            options={todo_statuses?.map((t) => {
                              return {
                                id: t._id.toString(),
                                title: t.title[intl.locale],
                              };
                            })}
                            formId={"status_pick"}
                            defaultValue={props._todo?.status?._id}
                            name={"status"}
                            label={""}
                            onChange={(event) => {
                              if (!event.target.value) return;

                              form.values.status = event.target.value;
                            }}
                          />
                        </Box>
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Select
                            options={[]}
                            name={"status"}
                            label={intl.formatMessage({
                              id: "STANDARD.STATUS",
                            })}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                  )}
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={status ? 6 : 12}
                    xl={status ? 6 : 12}
                  >
                    {loaded && props._todo ? (
                      <Box className={classes.input}>
                        <Typography variant={"body1"} sx={{ mb: 1 }}>
                          <FormattedMessage
                            id={"CASE.TODO.LEAD"}
                            defaultMessage={"CASE.TODO.LEAD"}
                          />
                        </Typography>
                        <Select
                          placeholder={intl.formatMessage({
                            id: "CASE.TODO.LEAD",
                          })}
                          showSearch
                          onChange={(value: string) => {
                            form.values.lead = value;
                          }}
                          onSearch={(value) => {
                            sendQuery(value);
                          }}
                          defaultValue={
                            props._todo.lead
                              ? props._todo?.lead?.firstname +
                                " " +
                                props._todo?.lead?.lastname
                              : undefined
                          }
                          filterOption={false}
                          style={{ width: "100%" }}
                          allowClear={true}
                          options={
                            leads &&
                            leads.map((user) => {
                              return {
                                value: user._id.toString(),
                                label: user.firstname + " " + user.lastname,
                              };
                            })
                          }
                          className={classes.antSelect}
                          onClear={() => {
                            sendQuery("");
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Select
                          name={"lead"}
                          options={[]}
                          label={intl.formatMessage({ id: "CASE.TODO.LEAD" })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2} mb={2}>
                  <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                    <>
                      {loaded && props._todo ? (
                        <Box className={classes.input}>
                          <Typography variant={"body1"} sx={{ mb: 1 }}>
                            <FormattedMessage
                              id={"STANDARD.DATE"}
                              defaultMessage={"STANDARD.DATE"}
                            />
                          </Typography>
                          <Space
                            className={classes.antSpace}
                            direction="vertical"
                            size={12}
                          >
                            <DatePicker
                              showTime={{ format: "HH:mm" }}
                              placeholder={intl.formatMessage({
                                id: "STANDARD.SELECTDATE",
                                defaultMessage: "Select date",
                              })}
                              className={classes.datePicker}
                              dropdownClassName={classes.antDropdown}
                              format={"DD-MM-YYYY HH:mm"}
                              onChange={(value, dateString) => {
                                form.values.date = (
                                  moment(
                                    dateString,
                                    "DD-MM-YYYY HH:mm"
                                  ).valueOf() / 1000
                                ).toString();
                              }}
                              defaultValue={moment(
                                props._todo?.date,
                                "X"
                              ).local()}
                            />
                          </Space>
                        </Box>
                      ) : (
                        <Skeleton width={"100%"}>
                          <Space
                            className={classes.antSpace}
                            direction="vertical"
                            size={12}
                          >
                            <DatePicker />
                          </Space>
                        </Skeleton>
                      )}
                      {loaded && props._todo ? (
                        <Box className={classes.input}>
                          <Typography variant={"body1"} sx={{ mb: 1, mt: 2.1 }}>
                            <FormattedMessage
                              id={"CASE.TODO.DEADLINEDATE"}
                              defaultMessage={"CASE.TODO.DEADLINEDATE"}
                            />
                          </Typography>
                          <Space
                            className={classes.antSpace}
                            direction="vertical"
                            size={12}
                          >
                            <DatePicker
                              showTime={{ format: "HH:mm" }}
                              placeholder={intl.formatMessage({
                                id: "STANDARD.SELECTDATE",
                                defaultMessage: "Select date",
                              })}
                              className={classes.datePicker}
                              dropdownClassName={classes.antDropdown}
                              format={"DD-MM-YYYY HH:mm"}
                              onChange={(value, dateString) => {
                                form.values.deadline_date = (
                                  moment(
                                    dateString,
                                    "DD-MM-YYYY HH:mm"
                                  ).valueOf() / 1000
                                ).toString();
                              }}
                              defaultValue={moment(
                                props._todo?.deadline_date,
                                "X"
                              ).local()}
                            />
                          </Space>
                        </Box>
                      ) : (
                        <Skeleton width={"100%"}>
                          <Space
                            className={classes.antSpace}
                            direction="vertical"
                            size={12}
                          >
                            <DatePicker />
                          </Space>
                        </Skeleton>
                      )}
                    </>
                  </Grid>

                  {editable && (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={editable ? 6 : 12}
                    lg={editable ? 6 : 12}
                    xl={editable ? 6 : 12}
                  >
                    {loaded && props._todo ? (
                      <Box className={classes.input}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                            {editable
                              ? intl.formatMessage({
                                  id: "STANDARD.DRAFT",
                                  defaultMessage: "Draft",
                                })
                              : intl.formatMessage({
                                  id: "STANDARD.DESCRIPTION",
                                  defaultMessage: "STANDARD.DESCRIPTION",
                                })}
                          </Typography>

                          <Button
                            onClick={() => {
                              isListening
                                ? handleStop("todo")
                                : handleStart("todo");
                            }}
                            style={{
                              padding: "10px",
                              borderRadius: "50%",
                              border: "none",
                              cursor: "pointer",
                            }}
                            sx={{ marginRight: "10px" }}
                            variant="outlined"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              id="Layer_1"
                              data-name="Layer 1"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill={isListening ? "red" : "green"}
                                d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm-2,7c0-1.105.895-2,2-2s2,.895,2,2v5c0,1.105-.895,2-2,2s-2-.895-2-2v-5Zm3,10.916v1.084c0,.552-.447,1-1,1s-1-.448-1-1v-1.084c-2.834-.477-5-2.948-5-5.916,0-.552.447-1,1-1s1,.448,1,1c0,2.206,1.794,4,4,4s4-1.794,4-4c0-.552.447-1,1-1s1,.448,1,1c0,2.968-2.166,5.439-5,5.916Z"
                              />
                            </svg>

                            <span
                              style={{
                                color: isListening ? "red" : "green",
                                marginLeft: "10px",
                              }}
                            >
                              {isListening
                                ? intl.formatMessage({
                                    id: "STANDARD.STOP_LISTENING",
                                    defaultMessage: "Stop Listening",
                                  })
                                : intl.formatMessage({
                                    id: "STANDARD.START_LISTENING",
                                    defaultMessage: "Start Listening",
                                  })}
                            </span>
                          </Button>
                          <Button
                            onClick={() => {
                              handleReset("todo");
                            }}
                            style={{
                              padding: "5px",
                              border: "none",
                              background: "red",
                              color: "#fff",
                              cursor: "pointer",
                            }}
                            sx={{ marginRight: "10px" }}
                            variant="outlined"
                          >
                            {intl.formatMessage({
                              id: "STANDARD.CLEAR",
                              defaultMessage: "Clear",
                            })}
                          </Button>
                        </Box>

                        <Controls.Input
                          multiline={true}
                          inputRef={descRef}
                          rows={5}
                          name={"description"}
                          disabled={editable}
                          label={""}
                          onChange={(event) => {
                            if (!event.target.value) {
                              lastProcessedTranscriptShow.current = "";
                              handleReset("todo");
                            }

                            form.values.description = event.target.value;
                            setDraftDesc(event.target.value);
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          rows={5}
                          name={"description"}
                          label={intl.formatMessage({
                            id: "STANDARD.DESCRIPTION",
                          })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                  {editable && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={editable ? 6 : 12}
                      lg={editable ? 6 : 12}
                      xl={editable ? 6 : 12}
                    >
                      {loaded && props._todo ? (
                        <Box className={classes.input}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              height: 44,
                            }}
                          >
                            <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                              {editable
                                ? intl.formatMessage({
                                    id: "STANDARD.ORIGINAL",
                                    defaultMessage: "Original",
                                  })
                                : intl.formatMessage({
                                    id: "STANDARD.DESCRIPTION",
                                    defaultMessage: "STANDARD.DESCRIPTION",
                                  })}
                            </Typography>
                          </Box>
                          <Controls.Input
                            multiline={true}
                            inputRef={orgDescRef}
                            rows={5}
                            name={"description"}
                            label={""}
                            onChange={(event) => {
                              if (!event.target.value) return;

                              form.values.description_org = event.target.value;
                              setOrgDesc(event.target.value);
                            }}
                          />
                        </Box>
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Input
                            rows={5}
                            name={"description"}
                            label={intl.formatMessage({
                              id: "STANDARD.DESCRIPTION",
                            })}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                  )}
                </Grid>
                {props.show_note && (
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                      <Divider sx={{ mb: 1 }}>
                        <Chip
                          label={intl.formatMessage({ id: "MENU.NOTES" })}
                          sx={{
                            backgroundColor: "#0D99FF",
                            color: "#ffffff",
                          }}
                        />
                      </Divider>
                    </Grid>
                    <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton
                          className={`${classes.iconButtonAdd}`}
                          sx={{
                            width: "92px",
                            height: "40px",
                            display: "flex",
                            // backgroundColor: "#556EE6",
                            borderRadius: 1,
                            gap: "6px",
                          }}
                          onClick={() => setNoteAddModal(true)}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.75 4.75C10.75 4.33579 10.4142 4 10 4C9.58579 4 9.25 4.33579 9.25 4.75V9.25H4.75C4.33579 9.25 4 9.58579 4 10C4 10.4142 4.33579 10.75 4.75 10.75H9.25V15.25C9.25 15.6642 9.58579 16 10 16C10.4142 16 10.75 15.6642 10.75 15.25V10.75H15.25C15.6642 10.75 16 10.4142 16 10C16 9.58579 15.6642 9.25 15.25 9.25H10.75V4.75Z"
                              fill="#556EE6"
                            />
                          </svg>
                        </IconButton>
                      </Box>
                    </Grid>
                    <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                      <Box
                        className=" kt-timeline-v2-forcase"
                        sx={{
                          "&.kt-timeline-v2-forcase:before": {
                            left: "2.4rem",
                          },
                        }}
                      >
                        <Box className="kt-timeline-v2__items-forcase">
                          {notes.length ? (
                            notes.map((note) => {
                              return (
                                <>
                                  <Box
                                    className="kt-timeline-v2__item"
                                    sx={{
                                      display: "flex!important",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box className="kt-timeline-v2__item-cricle">
                                      <Avatar
                                        variant="circular"
                                        sx={{ width: 40, height: 40 }}
                                        src={note.created_by?.file?.base64}
                                        alt={
                                          note.created_by?.firstname +
                                          " " +
                                          note.created_by?.lastname
                                        }
                                      />
                                    </Box>
                                    <Box
                                      className="kt-timeline-v2__item-text kt-padding-top-5"
                                      sx={{ width: "83%" }}
                                    >
                                      <Typography variant={"body1"}>
                                        <b style={{ color: "#000" }}>
                                          {note.title}
                                        </b>
                                      </Typography>
                                      <Typography
                                        variant={"body1"}
                                        sx={{
                                          overflowWrap: "anywhere",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        {intl.formatMessage({
                                          id: "STANDARD.DRAFT",
                                          defaultMessage: "Draft",
                                        }) +
                                          " - " +
                                          note.description}
                                      </Typography>
                                      <Typography
                                        variant={"body1"}
                                        sx={{
                                          overflowWrap: "anywhere",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        {intl.formatMessage({
                                          id: "STANDARD.ORIGINAL",
                                          defaultMessage: "Original",
                                        }) +
                                          " - " +
                                          note.description_org}
                                      </Typography>
                                      <Typography>
                                        {moment
                                          .unix(note._created_at)
                                          .local()
                                          .format("DD/MM/YYYY HH:mm")}{" "}
                                        -{" "}
                                        <b style={{ color: "#556EE6" }}>
                                          <a
                                            onClick={() => {
                                              history.push(
                                                `/users/${note.created_by?._id}`
                                              );
                                            }}
                                          >
                                            {note.created_by?.firstname +
                                              " " +
                                              note.created_by?.lastname}
                                          </a>
                                        </b>
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <IconButton
                                        onClick={() =>
                                          setNoteEditModal(note._id)
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                      <IconButton
                                        onClick={() => setConfirmId(note._id)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                  {/*<ListItem*/}
                                  {/*  className={classes.listItem}*/}
                                  {/*  alignItems="flex-start"*/}
                                  {/*  sx={{ padding: "0px" }}*/}
                                  {/*>*/}
                                  {/*  <ListItemButton*/}
                                  {/*    disableRipple={true}*/}
                                  {/*    disableTouchRipple={true}*/}
                                  {/*  >*/}
                                  {/*    <ListItemText*/}
                                  {/*      sx={{ mt: "10px!important" }}*/}
                                  {/*      primary={*/}
                                  {/*        <Box sx={{ display: "flex" }}>*/}
                                  {/*          <Box component="div" sx={{ flex: 1 }}>*/}
                                  {/*            {note.title}*/}
                                  {/*          </Box>*/}
                                  {/*          <Box>*/}
                                  {/*            <IconButton*/}
                                  {/*              onClick={() =>*/}
                                  {/*                setNoteEditModal(note._id)*/}
                                  {/*              }*/}
                                  {/*            >*/}
                                  {/*              <EditIcon />*/}
                                  {/*            </IconButton>*/}
                                  {/*            <IconButton*/}
                                  {/*              onClick={() =>*/}
                                  {/*                setConfirmId(note._id)*/}
                                  {/*              }*/}
                                  {/*            >*/}
                                  {/*              <DeleteIcon />*/}
                                  {/*            </IconButton>*/}
                                  {/*          </Box>*/}
                                  {/*        </Box>*/}
                                  {/*      }*/}
                                  {/*      secondary={*/}
                                  {/*        <Box*/}
                                  {/*          sx={{*/}
                                  {/*            display: "flex",*/}
                                  {/*            alignItems: "center",*/}
                                  {/*          }}*/}
                                  {/*        >*/}
                                  {/*          {moment*/}
                                  {/*            .unix(note._created_at)*/}
                                  {/*            .local()*/}
                                  {/*            .format("DD/MM/YYYY HH:mm")}{" "}*/}
                                  {/*          -{" "}*/}
                                  {/*          {note.created_by && (*/}
                                  {/*            <Typography variant={"body1"}>*/}
                                  {/*              <b style={{ color: "#556EE6" }}>*/}
                                  {/*                <a*/}
                                  {/*                  onClick={() => {*/}
                                  {/*                    history.push(*/}
                                  {/*                      `/users/${note.created_by?._id}`*/}
                                  {/*                    );*/}
                                  {/*                  }}*/}
                                  {/*                >*/}
                                  {/*                  {note.created_by?.firstname +*/}
                                  {/*                    " " +*/}
                                  {/*                    note.created_by?.lastname}*/}
                                  {/*                </a>*/}
                                  {/*              </b>*/}
                                  {/*            </Typography>*/}
                                  {/*          )}*/}
                                  {/*        </Box>*/}
                                  {/*      }*/}
                                  {/*    />*/}
                                  {/*  </ListItemButton>*/}
                                  {/*</ListItem>*/}
                                  <Box>
                                    {note.documents?.map((_document) => {
                                      return (
                                        <Grid
                                          item
                                          xs={2}
                                          sm={2}
                                          md={2}
                                          lg={2}
                                          xl={2}
                                          sx={{ mb: 1, cursor: "pointer" }}
                                          onClick={() => {
                                            if (_document.type == "img") {
                                              window.open(
                                                process.env
                                                  .REACT_APP_FILES_BASE_URL +
                                                  "/storage/" +
                                                  _document._id +
                                                  "/" +
                                                  _document.name,
                                                "_blank"
                                              );
                                            } else {
                                              handleFile(
                                                _document._id,
                                                _document.name
                                              );
                                            }
                                          }}
                                        >
                                          <Tooltip
                                            title={
                                              <div className="text-center">{`${_document.name}`}</div>
                                            }
                                            getPopupContainer={() =>
                                              document.getElementById(
                                                "attachments"
                                              )!
                                            }
                                          >
                                            <img
                                              alt="avatar"
                                              style={{
                                                objectFit: "contain",
                                                width: "100%",
                                                height: "100%",
                                              }}
                                              src={getFileExtensionIcon(
                                                _document
                                              )}
                                              className="cursor-pointer rounded"
                                            />
                                          </Tooltip>
                                        </Grid>
                                      );
                                    })}
                                  </Box>
                                </>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </form>
            </DialogContent>
          }
        </Dialog>
      </PerfectScrollbar>
      {noteAddModal && (
        <Dialog
          open={noteAddModal}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={() => {
            setNoteAddModal(false);
            setNoteTitle("");
            setNoteDesc("");
          }}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"CASE.NOTE.ADD"}
                  defaultMessage={"CASE.NOTE.ADD"}
                />
              </Typography>
              <Button
                onClick={() => {
                  handleNoteGrammar();
                }}
                sx={{ marginRight: "10px" }}
                variant="contained"
              >
                {intl.formatMessage({
                  id: "STANDARD.GRAMMAR_CORRECTION",
                  defaultMessage: "Grammar correction",
                })}
              </Button>
              <Button
                onClick={() => {
                  handleNoteHighLevelGrammar();
                }}
                sx={{ marginRight: "10px" }}
                variant="contained"
              >
                {intl.formatMessage({
                  id: "STANDARD.HIGHER_LEVEL",
                  defaultMessage: "Higher level correction",
                })}
              </Button>
              <Button
                form={"folder_note_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"folder_note_form"}
              onSubmit={(e) => form.handleSubmit(e, handleNoteSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Controls.Input
                      name={"title"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        setNoteTitle(event.target.value);
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={noteEditable ? 6 : 12}
                  lg={noteEditable ? 6 : 12}
                  xl={noteEditable ? 6 : 12}
                >
                  {loaded ? (
                    <>
                      <Button
                        onClick={() => {
                          isNoteListening
                            ? handleStop("note")
                            : handleStart("note");
                        }}
                        style={{
                          padding: "10px",
                          borderRadius: "50%",
                          border: "none",
                          cursor: "pointer",
                        }}
                        sx={{ marginRight: "10px" }}
                        variant="outlined"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_1"
                          data-name="Layer 1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill={isNoteListening ? "red" : "green"}
                            d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm-2,7c0-1.105.895-2,2-2s2,.895,2,2v5c0,1.105-.895,2-2,2s-2-.895-2-2v-5Zm3,10.916v1.084c0,.552-.447,1-1,1s-1-.448-1-1v-1.084c-2.834-.477-5-2.948-5-5.916,0-.552.447-1,1-1s1,.448,1,1c0,2.206,1.794,4,4,4s4-1.794,4-4c0-.552.447-1,1-1s1,.448,1,1c0,2.968-2.166,5.439-5,5.916Z"
                          />
                        </svg>

                        <span
                          style={{
                            color: isNoteListening ? "red" : "green",
                            marginLeft: "10px",
                          }}
                        >
                          {isNoteListening
                            ? intl.formatMessage({
                                id: "STANDARD.STOP_LISTENING",
                                defaultMessage: "Stop Listening",
                              })
                            : intl.formatMessage({
                                id: "STANDARD.START_LISTENING",
                                defaultMessage: "Start Listening",
                              })}
                        </span>
                      </Button>

                      <Button
                        onClick={() => {
                          handleReset("note");
                        }}
                        style={{
                          padding: "5px",
                          border: "none",
                          background: "red",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        sx={{ marginRight: "10px" }}
                        variant="outlined"
                      >
                        {intl.formatMessage({
                          id: "STANDARD.CLEAR",
                          defaultMessage: "Clear",
                        })}
                      </Button>
                      <Controls.Input
                        multiline={true}
                        rows={5}
                        disabled={noteEditable}
                        inputRef={noteDescRef}
                        name={"description"}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          setNoteDesc(event.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        rows={5}
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                {noteEditable && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={noteEditable ? 6 : 12}
                    lg={noteEditable ? 6 : 12}
                    xl={noteEditable ? 6 : 12}
                  >
                    {loaded ? (
                      <Box className={classes.input}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            height: 44,
                          }}
                        >
                          <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                            {noteEditable
                              ? intl.formatMessage({
                                  id: "STANDARD.ORIGINAL",
                                  defaultMessage: "Original",
                                })
                              : intl.formatMessage({
                                  id: "STANDARD.DESCRIPTION",
                                  defaultMessage: "STANDARD.DESCRIPTION",
                                })}
                          </Typography>
                        </Box>
                        <Controls.Input
                          multiline={true}
                          rows={5}
                          name={"description"}
                          label={""}
                          defaultValue={noteOrgDescAfter}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            setNoteOrgDesc(event.target.value);
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          rows={5}
                          name={"description"}
                          label={intl.formatMessage({
                            id: "STANDARD.DESCRIPTION",
                          })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                )}
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Box className={classes.dropzone}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        {intl.formatMessage({
                          id: "STANDARD.FILES",
                          defaultMessage: "Files",
                        })}
                      </Typography>
                      <Attachments {...{ setFiles, files }} />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"files"}
                        options={[]}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
      {noteEditModal && note && (
        <Dialog
          open={Boolean(noteEditModal)}
          onClose={() => {
            setNoteEditModal("");
            setNoteTitle("");
            setNoteDesc("");
            setNoteOrgDesc("");
            setNoteEditable(false);
            setNote(undefined);
          }}
          fullWidth={true}
          maxWidth={"sm"}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              pr: 2,
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"CASE.NOTE.SHOW"}
                  defaultMessage={"CASE.NOTE.SHOW"}
                />
              </Typography>

              <Button
                onClick={() => {
                  handleNoteGrammar();
                }}
                sx={{ marginRight: "10px" }}
                variant="contained"
              >
                {intl.formatMessage({
                  id: "STANDARD.GRAMMAR_CORRECTION",
                  defaultMessage: "Grammar correction",
                })}
              </Button>
              <Button
                onClick={() => {
                  handleNoteHighLevelGrammar();
                }}
                sx={{ marginRight: "10px" }}
                variant="contained"
              >
                {intl.formatMessage({
                  id: "STANDARD.HIGHER_LEVEL",
                  defaultMessage: "Higher level correction",
                })}
              </Button>
              <Button
                form={"note_form"}
                autoFocus
                color="inherit"
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"note_form"}
              onSubmit={(e) => form.handleSubmit(e, handleUpdateNote)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && note ? (
                    <Controls.Input
                      name={"title"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      defaultValue={note?.title}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        setNoteTitle(event.target.value);
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={noteEditable ? 6 : 12}
                  lg={noteEditable ? 6 : 12}
                  xl={noteEditable ? 6 : 12}
                >
                  {loaded && note ? (
                    <Box className={classes.input}>
                      <Button
                        onClick={() => {
                          isNoteListening
                            ? handleStop("note")
                            : handleStart("note");
                        }}
                        style={{
                          padding: "10px",
                          borderRadius: "50%",
                          border: "none",
                          cursor: "pointer",
                        }}
                        sx={{ marginRight: "10px" }}
                        variant="outlined"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_1"
                          data-name="Layer 1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill={isNoteListening ? "red" : "green"}
                            d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm-2,7c0-1.105.895-2,2-2s2,.895,2,2v5c0,1.105-.895,2-2,2s-2-.895-2-2v-5Zm3,10.916v1.084c0,.552-.447,1-1,1s-1-.448-1-1v-1.084c-2.834-.477-5-2.948-5-5.916,0-.552.447-1,1-1s1,.448,1,1c0,2.206,1.794,4,4,4s4-1.794,4-4c0-.552.447-1,1-1s1,.448,1,1c0,2.968-2.166,5.439-5,5.916Z"
                          />
                        </svg>

                        <span
                          style={{
                            color: isNoteListening ? "red" : "green",
                            marginLeft: "10px",
                          }}
                        >
                          {isNoteListening
                            ? intl.formatMessage({
                                id: "STANDARD.STOP_LISTENING",
                                defaultMessage: "Stop Listening",
                              })
                            : intl.formatMessage({
                                id: "STANDARD.START_LISTENING",
                                defaultMessage: "Start Listening",
                              })}
                        </span>
                      </Button>

                      <Button
                        onClick={() => {
                          handleReset("note");
                        }}
                        style={{
                          padding: "5px",
                          border: "none",
                          background: "red",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        sx={{ marginRight: "10px" }}
                        variant="outlined"
                      >
                        {intl.formatMessage({
                          id: "STANDARD.CLEAR",
                          defaultMessage: "Clear",
                        })}
                      </Button>
                      <Controls.Input
                        multiline={true}
                        rows={5}
                        name={"description"}
                        inputRef={noteShowDescRef}
                        disabled={noteEditable}
                        label={""}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          setNoteDesc(event.target.value);
                        }}
                      />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        rows={5}
                        name={"description"}
                        label={intl.formatMessage({
                          id: "STANDARD.DESCRIPTION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                {noteEditable && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={noteEditable ? 6 : 12}
                    lg={noteEditable ? 6 : 12}
                    xl={noteEditable ? 6 : 12}
                  >
                    {loaded && note ? (
                      <Box className={classes.input}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            height: 44,
                          }}
                        >
                          <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                            {noteEditable
                              ? intl.formatMessage({
                                  id: "STANDARD.ORIGINAL",
                                  defaultMessage: "Original",
                                })
                              : intl.formatMessage({
                                  id: "STANDARD.DESCRIPTION",
                                  defaultMessage: "STANDARD.DESCRIPTION",
                                })}
                          </Typography>
                        </Box>
                        <Controls.Input
                          multiline={true}
                          rows={5}
                          name={"description"}
                          label={""}
                          defaultValue={noteOrgDescAfter}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            setNoteOrgDesc(event.target.value);
                          }}
                        />
                      </Box>
                    ) : (
                      <Skeleton width={"100%"}>
                        <Controls.Input
                          rows={5}
                          name={"description"}
                          label={intl.formatMessage({
                            id: "STANDARD.DESCRIPTION",
                          })}
                          onChange={() => {}}
                        />
                      </Skeleton>
                    )}
                  </Grid>
                )}
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && note ? (
                    <Box className={classes.dropzone}>
                      <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                        Files
                      </Typography>
                      <AttachmentsInfo setParams={setFiles} params={note} />
                    </Box>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"files"}
                        options={[]}
                        label={""}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                {note?.activities && (
                  <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                    <Box className=" kt-timeline-v2-forcase">
                      <Box className="kt-timeline-v2__items-forcase">
                        {note?.activities.map((activity) => {
                          const description = intl.formatMessage({
                            id: activity.title,
                          });
                          const descriptionArray = description.split("-");
                          const title = descriptionArray[0].replace(
                            "{who}",
                            activity.replacement.title
                          );
                          return (
                            <Box className="kt-timeline-v2__item">
                              <Box className="kt-timeline-v2__item-cricle">
                                <Avatar
                                  variant="circular"
                                  sx={{ width: 24, height: 24 }}
                                  src={activity.replacement.avatar}
                                  alt={title}
                                />
                              </Box>
                              <Box className="kt-timeline-v2__item-text kt-padding-top-5">
                                <Typography variant={"body1"}>
                                  <b style={{ color: "#556EE6" }}>
                                    <a
                                      onClick={() => {
                                        history.push(
                                          `/users/${activity.replacement.id}`
                                        );
                                      }}
                                    >
                                      {title}
                                    </a>
                                  </b>
                                  {" - "} {descriptionArray[1]}
                                </Typography>
                                <Typography>
                                  {moment
                                    .unix(activity.created_at)
                                    .local()
                                    .format("DD-MM-YYYY HH:mm")}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}

      {confirmId ? (
        <ConfirmDialog
          title="Delete note?"
          open={Boolean(confirmId)}
          setOpen={() => setConfirmId(null)}
          onConfirm={() => {
            if (props._todo?._id) handleDeleteNote(props._todo._id, confirmId);
          }}
        >
          Are you sure you want to delete this note?
        </ConfirmDialog>
      ) : (
        <></>
      )}
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default TodoShowDialogue;
