import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import FocusArea from "./FocusArea";
import FocusType from "./FocusType";
import SystemLanguage from "./SystemLanguage";
import Language from "./Language";
import Question from "./Question";
import QuestionCategory from "./QuestionCategory";
import TodoCategory from "./TodoCategory";
import Color from "./Color";
import FinanceStatus from "./FinanceStatus";
import FolderCategory from "./FolderCategory";
import VacancyCity from "./VacancyCity";
import VacancyLanguage from "./VacancyLanguage";
import VacancyStatus from "./VacancyStatus";
import VacancyFolder from "./VacancyFolder";
import PdfFrontPageExport from "./PdfFrontPageExport";
import TimeTrackType from "./TimeTrackType";
import OfferTemplate from "./OfferTemplate";
import OfferStatus from "./OfferStatus";
import ContractStatus from "./ContractStatus";
import TodoStatus from "./TodoStatus";
import TodoType from "./TodoType";
import PlanStatus from "./PlanStatus";
import PlanType from "./PlanType";
import ReportStatus from "./ReportStatus";
import ReportType from "./ReportType";
import UserSessionType from "./UserSessionType";
import Goal from "./Goal";
import City from "./City";
import TransactionType from "./TransactionType";
import MeetingDurationType from "./MeetingDurationType";
import ReportInterval from "./ReportInterval";
import Currency from "./Currency";
import Position from "./Positions";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { FormattedMessage, useIntl } from "react-intl";
import TranslationForm from "./TranslationForm";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Role from "./Role";
import Switch from "@mui/material/Switch";
import Labels from "./Labels";
import ExpenseCategory from "./ExpenseCategory";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const useStyle = makeStyles(() => ({
  tabPanel: {
    "& .MuiTabPanel-root": {
      padding: "24px 0",
    },
    "& .MuiButtonBase-root": {
      lineHeight: "24px",
      // fontSize: 8,
    },
  },
}));

const tabs: Array<{
  lang: string;
  by?: string;
  key: string;
  component: () => JSX.Element;
}> = [
  {
    lang: "MENU.TRANSLATION_FORM",
    key: "translation_form",
    by: "main_settings",
    component: () => <TranslationForm />,
  },
  {
    lang: "MENU.ROLES",
    key: "roles",
    by: "main_settings",
    component: () => <Role />,
  },
  {
    lang: "MENU.FOCUS_AREAS",
    key: "focus_areas",
    by: "product_services",
    component: () => <FocusArea />,
  },
  {
    lang: "MENU.FOCUS_TYPES",
    key: "focus_types",
    by: "product_services",
    component: () => <FocusType />,
  },
  {
    lang: "MENU.POSITIONS",
    key: "positions",
    by: "product_services",
    component: () => <Position />,
  },
  {
    lang: "MENU.SYSTEM_LANGUAGES",
    key: "system_languages",
    by: "main_settings",
    component: () => <SystemLanguage />,
  },
  {
    lang: "MENU.LANGUAGES",
    key: "languages",
    by: "main_settings",
    component: () => <Language />,
  },
  {
    lang: "MENU.QUESTIONS",
    key: "questions",
    by: "trading_plan",
    component: () => <Question />,
  },
  {
    lang: "MENU.QUESTION_CATEGORIES",
    key: "question_categories",
    by: "trading_plan",
    component: () => <QuestionCategory />,
  },
  {
    lang: "MENU.USER_SESSION_TYPES",
    key: "user_session_types",
    by: "time_tracks",
    component: () => <UserSessionType />,
  },
  {
    lang: "MENU.GOALS",
    key: "goals",
    by: "trading_plan",
    component: () => <Goal />,
  },
  {
    lang: "MENU.TODO_CATEGORIES",
    key: "todo_categories",
    by: "calendar",
    component: () => <TodoCategory />,
  },
  {
    lang: "MENU.LABELS",
    key: "labels",
    by: "calendar",
    component: () => <Labels />,
  },
  {
    lang: "MENU.TODO_STATUSES",
    key: "todo_statuses",
    by: "calendar",
    component: () => <TodoStatus />,
  },
  {
    lang: "MENU.TODO_TYPES",
    key: "todo_types",
    by: "calendar",
    component: () => <TodoType />,
  },
  {
    lang: "MENU.PLAN_STATUSES",
    key: "plan_statuses",
    by: "trading_plan",
    component: () => <PlanStatus />,
  },
  {
    lang: "MENU.PLAN_TYPES",
    key: "plan_types",
    by: "trading_plan",
    component: () => <PlanType />,
  },
  {
    lang: "MENU.REPORT_STATUSES",
    key: "report_statuses",
    by: "report",
    component: () => <ReportStatus />,
  },
  {
    lang: "MENU.REPORT_TYPES",
    key: "report_types",
    by: "report",
    component: () => <ReportType />,
  },
  {
    lang: "MENU.REPORT_INTERVALS",
    key: "report_intervals",
    by: "report",
    component: () => <ReportInterval />,
  },
  {
    lang: "MENU.MEETING_DURATION_TYPES",
    key: "meeting_duration_types",
    by: "time_tracks",
    component: () => <MeetingDurationType />,
  },
  {
    lang: "MENU.COLORS",
    key: "colors",
    by: "main_settings",
    component: () => <Color />,
  },
  {
    lang: "MENU.CITIES",
    key: "cities",
    by: "main_settings",
    component: () => <City />,
  },
  {
    lang: "MENU.FINANCESTATUSES",
    key: "finance_statuses",
    by: "accounting",
    component: () => <FinanceStatus />,
  },
  {
    lang: "MENU.TRANSACTION_TYPES",
    key: "transaction_types",
    by: "accounting",
    component: () => <TransactionType />,
  },
  {
    lang: "MENU.FOLDERCATEGORIES",
    key: "folder_categories",
    by: "vacancy",
    component: () => <FolderCategory />,
  },
  {
    lang: "MENU.VACANCY_CITIES",
    key: "vacancy_cities",
    by: "vacancy",
    component: () => <VacancyCity />,
  },
  {
    lang: "MENU.VACANCY_LANGUAGES",
    key: "vacancy_languages",
    by: "vacancy",
    component: () => <VacancyLanguage />,
  },
  {
    lang: "MENU.VACANCY_STATUSES",
    key: "vacancy_statuses",
    by: "vacancy",
    component: () => <VacancyStatus />,
  },
  {
    lang: "MENU.VACANCY_FOLDERS",
    key: "vacancy_folders",
    by: "vacancy",
    component: () => <VacancyFolder />,
  },
  {
    lang: "MENU.OFFER_TEMPLATES",
    key: "offer_templates",
    by: "offer",
    component: () => <OfferTemplate />,
  },
  {
    lang: "MENU.OFFER_STATUSES",
    key: "offer_statuses",
    by: "offer",
    component: () => <OfferStatus />,
  },
  {
    lang: "MENU.CONTRACT_STATUSES",
    key: "contract_statuses",
    by: "contract",
    component: () => <ContractStatus />,
  },
  {
    lang: "MENU.TIME_TRACK_TYPES",
    key: "time_track_types",
    by: "time_tracks",
    component: () => <TimeTrackType />,
  },
  {
    lang: "MENU.PDF_FRONT_PAGE",
    key: "pdf_front_page",
    by: "report",
    component: () => <PdfFrontPageExport />,
  },
  {
    lang: "MENU.CURRENCY",
    key: "currency",
    // by: "report",
    component: () => <Currency />,
  },
  {
    lang: "MENU.EXPENSE_CATEGORY",
    key: "expense_category",
    // by: "report",
    component: () => <ExpenseCategory />,
  },
];

const Settings = () => {
  let { search } = useLocation();
  let query = useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyle();
  const [section, setSection] = useState<string>("0");
  const [hiddens, setHiddens] = useState<string[]>(
    JSON.parse(localStorage.getItem("perms") ?? "[]") || []
  );
  const [generalSwitch, setGeneralSwitch] = useState(true);

  const [type, setType] = useState(query.get("type") ?? "system_languages");

  const getIndex = (t: string): number | null => {
    let index = tabs.findIndex((tab) => tab.key === t);
    return index === -1 ? null : index;
  };

  const handleGeneralSwitchChange = (e: any) => {
    const checked = e.target.checked;
    setGeneralSwitch(checked);
    if (checked) {
      setHiddens([]);
    } else {
      setHiddens([
        "main_settings",
        "product_services",
        "time_tracks",
        "accounting",
        "report",
        "trading_plan",
        "calendar",
        "offer",
        "contract",
        "vacancy",
      ]);
    }
  };

  useEffect(() => {
    let section = getIndex(type);
    if (section) {
      setSection(section.toString());
    }
  }, [type]);

  React.useEffect(() => {
    localStorage.setItem("perms", JSON.stringify(hiddens));
  }, [hiddens]);

  return (
    <>
      <Box className={classes.tabPanel}>
        <Paper
          sx={{
            boxShadow: "unset!important",
            borderRadius: 0,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Box
            sx={{
              width: "100%",
              borderBottom: 1,
              borderColor: "divider",
              padding: "12px 24px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant={"inherit"} fontSize={14} fontWeight={500}>
              <FormattedMessage
                id={"GENERAL_SETTINGS"}
                defaultMessage={"General settings"}
              />
            </Typography>
            <Switch
              checked={generalSwitch}
              onChange={(e) => {
                handleGeneralSwitchChange(e);
              }}
            />
          </Box>

          <Grid
            container
            sx={{
              padding: "12px 24px",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Grid
              sx={{ marginBottom: "21px" }}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
            >
              <Grid container>
                <Grid xs={2} sm={2} md={2} lg={2} xl={2}>
                  <Switch
                    checked={!hiddens.includes("main_settings")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setHiddens(
                          hiddens.filter((x) => x !== "main_settings")
                        );
                      } else {
                        setHiddens(hiddens.concat(["main_settings"]));
                      }
                    }}
                  />
                </Grid>
                <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography variant={"h4"} fontSize={14}>
                    <FormattedMessage
                      id={"MAIN_SETTINGS_TITLE"}
                      defaultMessage={"Main settings"}
                    />
                  </Typography>
                  <Typography variant={"inherit"} fontSize={12}>
                    <FormattedMessage
                      id={"MAIN_SETTINGS_DESC"}
                      defaultMessage={
                        "Create and Choose roles, translation, color, languages and cities."
                      }
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sx={{ marginBottom: "21px" }}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
            >
              <Grid container>
                <Grid xs={2} sm={2} md={2} lg={2} xl={2}>
                  <Switch
                    checked={!hiddens.includes("product_services")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setHiddens(
                          hiddens.filter((x) => x !== "product_services")
                        );
                      } else {
                        setHiddens(hiddens.concat(["product_services"]));
                      }
                    }}
                  />
                </Grid>
                <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography variant={"h4"} fontSize={14}>
                    <FormattedMessage
                      id={"PRODUCT_SERVICES_TITLE"}
                      defaultMessage={"Products and services"}
                    />
                  </Typography>
                  <Typography variant={"inherit"} fontSize={12}>
                    <FormattedMessage
                      id={"PRODUCT_SERVICES_DESC"}
                      defaultMessage={"Create your own product and services"}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sx={{ marginBottom: "21px" }}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
            >
              <Grid container>
                <Grid xs={2} sm={2} md={2} lg={2} xl={2}>
                  <Switch
                    checked={!hiddens.includes("time_tracks")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setHiddens(hiddens.filter((x) => x !== "time_tracks"));
                      } else {
                        setHiddens(hiddens.concat(["time_tracks"]));
                      }
                    }}
                  />
                </Grid>
                <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography variant={"h4"} fontSize={14}>
                    <FormattedMessage
                      id={"TIME_TRACK_TITLE"}
                      defaultMessage={"Timetracks"}
                    />
                  </Typography>
                  <Typography variant={"inherit"} fontSize={12}>
                    <FormattedMessage
                      id={"TIME_TRACK_DESC"}
                      defaultMessage={
                        "Customise what type on timetracks is needed"
                      }
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sx={{ marginBottom: "21px" }}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
            >
              <Grid container>
                <Grid xs={2} sm={2} md={2} lg={2} xl={2}>
                  <Switch
                    checked={!hiddens.includes("accounting")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setHiddens(hiddens.filter((x) => x !== "accounting"));
                      } else {
                        setHiddens(hiddens.concat(["accounting"]));
                      }
                    }}
                  />
                </Grid>
                <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography variant={"h4"} fontSize={14}>
                    <FormattedMessage
                      id={"ACCOUNTING_TITLE"}
                      defaultMessage={"Accounting"}
                    />
                  </Typography>
                  <Typography variant={"inherit"} fontSize={12}>
                    <FormattedMessage
                      id={"ACCOUNTING_DESC"}
                      defaultMessage={"Define finance and transaction types"}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sx={{ marginBottom: "21px" }}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
            >
              <Grid container>
                <Grid xs={2} sm={2} md={2} lg={2} xl={2}>
                  <Switch
                    checked={!hiddens.includes("report")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setHiddens(hiddens.filter((x) => x !== "report"));
                      } else {
                        setHiddens(hiddens.concat(["report"]));
                      }
                    }}
                  />
                </Grid>
                <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography variant={"h4"} fontSize={14}>
                    <FormattedMessage
                      id={"REPORTS_TITLE"}
                      defaultMessage={"Reports"}
                    />
                  </Typography>
                  <Typography variant={"inherit"} fontSize={12}>
                    <FormattedMessage
                      id={"REPORTS_DESC"}
                      defaultMessage={
                        "Create and define Evaluation and statusreports."
                      }
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sx={{ marginBottom: "21px" }}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
            >
              <Grid container>
                <Grid xs={2} sm={2} md={2} lg={2} xl={2}>
                  <Switch
                    checked={!hiddens.includes("trading_plan")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setHiddens(hiddens.filter((x) => x !== "trading_plan"));
                      } else {
                        setHiddens(hiddens.concat(["trading_plan"]));
                      }
                    }}
                  />
                </Grid>
                <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography variant={"h4"} fontSize={14}>
                    <FormattedMessage
                      id={"TRADING_PLAN_TITLE"}
                      defaultMessage={"TradingPlan"}
                    />
                  </Typography>
                  <Typography variant={"inherit"} fontSize={12}>
                    <FormattedMessage
                      id={"TRADING_PLAN_DESC"}
                      defaultMessage={
                        "Customise goals - Can be synchronised with reports"
                      }
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
              <Grid container>
                <Grid xs={2} sm={2} md={2} lg={2} xl={2}>
                  <Switch
                    checked={!hiddens.includes("calendar")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setHiddens(hiddens.filter((x) => x !== "calendar"));
                      } else {
                        setHiddens(hiddens.concat(["calendar"]));
                      }
                    }}
                  />
                </Grid>
                <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography variant={"h4"} fontSize={14}>
                    <FormattedMessage
                      id={"CALENDAR_TITLE"}
                      defaultMessage={"Calendar"}
                    />
                  </Typography>
                  <Typography variant={"inherit"} fontSize={12}>
                    <FormattedMessage
                      id={"CALENDAR_DESC"}
                      defaultMessage={"Costumise statuses, categories and type"}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
              <Grid container>
                <Grid xs={2} sm={2} md={2} lg={2} xl={2}>
                  <Switch
                    checked={!hiddens.includes("offer")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setHiddens(hiddens.filter((x) => x !== "offer"));
                      } else {
                        setHiddens(hiddens.concat(["offer"]));
                      }
                    }}
                  />
                </Grid>
                <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography variant={"h4"} fontSize={14}>
                    <FormattedMessage
                      id={"OFFER_TITLE"}
                      defaultMessage={"Offer and new inquirys"}
                    />
                  </Typography>
                  <Typography variant={"inherit"} fontSize={12}>
                    <FormattedMessage
                      id={"OFFER_DESC"}
                      defaultMessage={"Create customised offer templates"}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
              <Grid container>
                <Grid xs={2} sm={2} md={2} lg={2} xl={2}>
                  <Switch
                    checked={!hiddens.includes("contract")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setHiddens(hiddens.filter((x) => x !== "contract"));
                      } else {
                        setHiddens(hiddens.concat(["contract"]));
                      }
                    }}
                  />
                </Grid>
                <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography variant={"h4"} fontSize={14}>
                    <FormattedMessage
                      id={"CONTRACT_TITLE"}
                      defaultMessage={"Contracts"}
                    />
                  </Typography>
                  <Typography variant={"inherit"} fontSize={12}>
                    <FormattedMessage
                      id={"CONTRACT_DESC"}
                      defaultMessage={
                        "Create customised contracts, for employees and partners"
                      }
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
              <Grid container>
                <Grid xs={2} sm={2} md={2} lg={2} xl={2}>
                  <Switch
                    checked={!hiddens.includes("vacancy")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setHiddens(hiddens.filter((x) => x !== "vacancy"));
                      } else {
                        setHiddens(hiddens.concat(["vacancy"]));
                      }
                    }}
                  />
                </Grid>
                <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography variant={"h4"} fontSize={14}>
                    <FormattedMessage
                      id={"HR_TITLE"}
                      defaultMessage={"HR settings"}
                    />
                  </Typography>
                  <Typography variant={"inherit"} fontSize={12}>
                    <FormattedMessage
                      id={"HR_DESC"}
                      defaultMessage={"Define and create details for Jobbank"}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <TabContext value={section}>
          <Box>
            <Paper
              sx={{
                boxShadow: "unset!important",
                borderRadius: 0,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
              }}
            >
              <TabList
                variant="scrollable"
                scrollButtons="auto"
                onChange={(e, newValue) => setSection(newValue)}
                aria-label="basic tabs example"
              >
                {tabs
                  .sort(function (a, b) {
                    if (
                      intl.formatMessage({ id: a.lang }).toLowerCase() <
                      intl.formatMessage({ id: b.lang }).toLowerCase()
                    )
                      return -1;
                    if (
                      intl.formatMessage({ id: a.lang }).toLowerCase() >
                      intl.formatMessage({ id: b.lang }).toLowerCase()
                    )
                      return 1;
                    return 0;
                  })
                  .map((tab, index) => {
                    if (hiddens.includes(tab?.by as string)) return;

                    return (
                      <Tab
                        label={
                          <Typography
                            fontSize={14}
                            sx={{ textTransform: "none" }}
                          >
                            {intl.formatMessage({ id: tab.lang })}
                          </Typography>
                        }
                        value={index.toString()}
                        data-cy-id={tab.key}
                        onClick={() => {
                          history.push("settings?type=" + tab.key);
                        }}
                      />
                    );
                  })}
              </TabList>
            </Paper>
          </Box>
          {tabs.map((tab, index) => {
            return (
              <TabPanel value={index.toString()}>{tab.component()}</TabPanel>
            );
          })}
        </TabContext>
      </Box>
    </>
  );
};

export default Settings;
