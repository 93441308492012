import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Snackbar from "../../../widgets/Snackbar";
import { settingService } from "../../../services";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as setting from "../../../store/ducks/setting.duck";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import Controls from "app/widgets/uncontrolled";
import { TransitionProps } from "@mui/material/transitions";
import { useForm } from "../../../hooks/useForm";
import { RootState } from "../../../store/store";
import { ISetting } from "../../../services/setting.service";
import { Palette } from "../../../widgets/Palette";

interface FormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: { [key: string]: string } | null;
  color: string;
  active: number;
  feedback_active: number;
};

const ShowTimetrackType = (props: FormProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const updateSetting = useCallback(
    (s) => dispatch({ type: setting.actionTypes.Update, payload: s }),
    [dispatch]
  );
  const form = useForm<FormStateValues>();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const { system_languages } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const [resource, setResource] = useState<ISetting>();
  const colorRef = useRef<HTMLInputElement>();

  useEffect(() => {
    settingService
      .getOne(props._id, [
        {
          name: "type",
          value: "time_track_types",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        let { data: resource } = data;
        setResource(resource);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  useEffect(() => {
    if (resource) {
      form.values.title = resource.title;
      form.values.color = resource.color;
      form.values.active = resource.active;
      form.values.feedback_active = resource.feedback_active;
    }
  }, [resource]);
  const handleSave = () => {
    let validated = form.validate();
    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    settingService
      .update(props._id, validated, [
        {
          name: "type",
          value: "time_track_types",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        let { data: setting } = data;
        updateSetting(setting);

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const handleClose = () => {
    props.setDialogue(false);
  };

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"sm"}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "rgb(245, 245, 245)",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"DIALOGUE.EDIT"}
                  defaultMessage={"DIALOGUE.EDIT"}
                />
              </Typography>
              <Button
                form={"setting_form"}
                autoFocus
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"setting_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                {system_languages &&
                  system_languages.map((system_language) => {
                    return (
                      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                        {loaded && resource ? (
                          <Controls.Input
                            name={`title_${system_language.short_code}`}
                            label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                            defaultValue={
                              resource?.title[system_language.short_code]
                            }
                            onChange={(event) => {
                              if (!event.target.value) return;

                              if (!form.values.title) {
                                form.values.title = {};
                              }

                              form.values.title[system_language.short_code] =
                                event.target.value;

                              event.preventDefault();
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {system_language.short_code.toUpperCase()}
                                </InputAdornment>
                              ),
                            }}
                          />
                        ) : (
                          <Skeleton width={"100%"}>
                            <Controls.Input
                              name={`title_${system_language.short_code}`}
                              label={intl.formatMessage({
                                id: "STANDARD.TITLE",
                              })}
                              onChange={() => {}}
                            />
                          </Skeleton>
                        )}
                      </Grid>
                    );
                  })}

                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && resource ? (
                    <>
                      <Controls.Input
                        name={"color"}
                        label={intl.formatMessage({ id: "STANDARD.COLOR" })}
                        inputRef={colorRef}
                        defaultValue={resource?.color}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.color = event.target.value;

                          event.preventDefault();
                        }}
                      />
                      <Grid container>
                        <Palette
                          onPick={(color) => {
                            if (color) {
                              form.values.color = color;
                              if (colorRef.current)
                                colorRef.current.value = color;
                            } else {
                              form.values.color = "";
                              if (colorRef.current) colorRef.current.value = "";
                            }
                          }}
                          defaultValue={resource?.color}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"color"}
                        label={intl.formatMessage({ id: "STANDARD.COLOR" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && resource ? (
                    <Controls.Select
                      name={"active"}
                      formId={"status"}
                      defaultValue={resource?.active}
                      options={[
                        {
                          id: "1",
                          title: intl.formatMessage({
                            id: "STANDARD.ACTIVE",
                          }),
                        },
                        {
                          id: "0",
                          title: intl.formatMessage({
                            id: "STANDARD.DEACTIVE",
                          }),
                        },
                      ]}
                      label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.active = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"active"}
                        options={[]}
                        label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && resource ? (
                    <Controls.Select
                      name={"feedback"}
                      formId={"feedback"}
                      defaultValue={resource?.feedback_active}
                      options={[
                        {
                          id: "1",
                          title: intl.formatMessage({ id: "STANDARD.ACTIVE" }),
                        },
                        {
                          id: "0",
                          title: intl.formatMessage({
                            id: "STANDARD.DEACTIVE",
                          }),
                        },
                      ]}
                      label={intl.formatMessage({
                        id: "STANDARD.FEEDBACK_ACTIVE",
                        defaultMessage: "Feedback active",
                      })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.feedback_active = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"status"}
                        options={[]}
                        label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowTimetrackType;
