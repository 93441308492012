import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import Controls from "../../widgets/uncontrolled";
import Snackbar from "../../widgets/Snackbar";
import { Skeleton } from "@mui/lab";
import { TransitionProps } from "@mui/material/transitions";
import { login } from "../../crud/auth.crud";
import { useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../../store/store";
import { businessService, IBusiness } from "../../services/business.service";
import * as auth from "../../store/ducks/auth.duck";
import { stringAvatar } from "../../widgets/AvatarBackground";
import * as setting from "../../store/ducks/setting.duck";
import * as i18n from "../../store/ducks/i18n.duck";
import i18nProvider from "../../providers/I18nProvider";

type Props = {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
};

const ChangeCompany = (props: Props) => {
  const [selectedIndex, setSelectedIndex] = React.useState();
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }: RootState) => auth);
  const [businesses, setBusinesses] = useState<IBusiness[]>([]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleListItemClick = (business: IBusiness) => {
    {
      user?.role?.slug === "super-admin" &&
        dispatch(auth.actions.updateBusiness(business));
      dispatch(setting.actions.get());
    }

    business.default_language
      ? dispatch(i18n.actions.setLanguage(business.default_language.short_code))
      : dispatch(i18n.actions.setLanguage("en"));

    setTimeout(() => {
      window.location.replace("/");
    }, 1000);
  };

  useEffect(() => {
    if (props.open) {
      // if (user?.role?.slug === "super-admin") {
      businessService.getAll().then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: businesses } = data;

        setBusinesses(businesses);
      });
      // } else {
      //   user && setBusinesses(user.businesses);
      // }
    }
  }, [props.open]);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        sx={{
          borderRadius: 1,
        }}
      >
        <DialogContent sx={{ p: "unset" }}>
          <List
            sx={{
              width: "100%",
              backgroundColor: "background.paper",
              pt: "0px!important",
              pb: "0px!important",
            }}
          >
            {businesses.map((b) => {
              return (
                <ListItem
                  secondaryAction={
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 17.2488C9.00018 17.0497 9.08037 16.8589 9.22294 16.7182L13.1102 12.8778C13.2278 12.7616 13.3212 12.6237 13.3848 12.4718C13.4485 12.32 13.4813 12.1572 13.4813 11.9928C13.4813 11.8285 13.4485 11.6657 13.3848 11.5139C13.3212 11.362 13.2278 11.224 13.1102 11.1078L9.228 7.27248C9.08956 7.13087 9.01296 6.9412 9.01469 6.74433C9.01642 6.54746 9.09635 6.35913 9.23726 6.21992C9.37818 6.08071 9.5688 6.00174 9.76807 6.00003C9.96735 5.99832 10.1593 6.074 10.3027 6.21077L14.1848 10.0431C14.7068 10.5598 15 11.2601 15 11.9901C15 12.7201 14.7068 13.4203 14.1848 13.937L10.2976 17.7799C10.1913 17.885 10.0559 17.9566 9.90838 17.9856C9.76091 18.0146 9.60804 17.9997 9.46912 17.9428C9.33021 17.886 9.21148 17.7897 9.12798 17.6661C9.04447 17.5426 8.99993 17.3974 9 17.2488Z"
                        fill="#767C91"
                      />
                    </svg>
                  }
                  disablePadding
                >
                  <ListItemButton
                    selected={selectedIndex === b._id}
                    data-cy-name={b.slug}
                    onClick={(event) => {
                      handleListItemClick(b);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={b.name}
                        sx={{
                          backgroundColor: "transparent",
                          "& .MuiAvatar-img": {
                            objectFit: "scale-down",
                          },
                        }}
                        src={b.file && b.file?.base64}
                      >
                        {b.name?.charAt(0)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant={"body1"} sx={{ color: "#0D99FF" }}>
                          {b.name}
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>{b.description}</React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ChangeCompany;
