import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { settingService } from "../../services";
import { IAction, IRestApiCollection, IRestApiError } from "../../interfaces";
import { ISetting, SettingsType } from "../../services/setting.service";
import { checkKey } from "../../utils/standart";

export const actionTypes = {
  //xhr
  Get: "Get settings",
  Get_SUCCESS: "Get settings success",
  Get_PENDING: "Get settings pending",
  Get_ERROR: "Get settings error",
  //app
  Add: "Add settings",
  Update: "Update settings",
  Delete: "Update settings",
  ChangeUIMode: "Update ui mode",
  getSettingSwitches: "Get setting switches",
};

type SettingsStateType = {
  [key in SettingsType]?: Array<ISetting>;
};

export interface ISettingsState extends SettingsStateType {
  loading: boolean;
  cached: boolean;
  errors: Array<any>;
  uiMode: "dark" | "light";
}

const initialSettingsState: ISettingsState = {
  loading: false,
  cached: false,
  errors: [],
  uiMode: "light",
};

export const reducer = persistReducer(
  {
    storage,
    key: "setting",
    whitelist: [
      "user_session_types",
      "expense_categories",
      "budget_intervals",
      "cached",
      "currencies",
      "meeting_duration_types",
      "report_intervals",
      "plan_intervals",
      "report_types",
      "time_track_types",
      "todo_types",
      "vacancy_languages",
      "pdf_front_page_export",
      "offer_templates",
      "offer_statuses",
      "vacancy_statuses",
      "vacancy_cities",
      "todo_statuses",
      "vacancy_folders",
      "finance_statuses",
      "plan_statuses",
      "plan_types",
      "folder_categories",
      "colors",
      "todo_categories",
      "question_categories",
      "cities",
      "goals",
      "questions",
      "languages",
      "system_languages",
      "focus_areas",
      "focus_types",
      "transaction_types",
      "report_statuses",
      "contract_statuses",
      "labels",
      "notice_commune",
      "positions",
      "default_languages",
      "uiMode",
    ],
  },
  (state: ISettingsState = initialSettingsState, action: IAction) => {
    switch (action.type) {
      case actionTypes.ChangeUIMode: {
        let mode = state.uiMode;

        if (mode === "dark") mode = "light";
        else if (mode === "light") mode = "dark";

        return { ...state, uiMode: mode };
      }
      case actionTypes.Get_PENDING: {
        let { silent } = action.payload;
        return { ...state, loading: !silent };
      }
      case actionTypes.Get_ERROR: {
        const { error } = action.payload;

        return { ...state, loading: false, error: error };
      }
      case actionTypes.Get_SUCCESS: {
        const { grouped } = action.payload;
        let newState: SettingsStateType = {};

        for (let key in grouped) {
          if (!checkKey<SettingsStateType>(key, grouped)) {
            continue;
          }
          newState[key] = grouped[key];
        }

        let settings: ISettingsState = {
          ...state,
          loading: false,
          ...newState,
        };

        return {
          ...settings,
        };
      }
      case actionTypes.Add: {
        let setting: ISetting = action.payload;
        let _state: ISettingsState = state;

        if (setting.name) {
          _state[setting.name]?.push(setting);
        }

        return {
          ..._state,
        };
      }
      case actionTypes.Update: {
        let setting: ISetting = action.payload;
        let _state: ISettingsState = state;

        if (setting.name && state[setting.name]) {
          _state[setting.name] = state[setting.name]?.map((s) => {
            if (s._id === setting._id) return setting;
            else return s;
          });
        }

        return {
          ..._state,
        };
      }
      case actionTypes.Delete: {
        let setting: ISetting = action.payload;
        let _state: ISettingsState = state;

        if (setting.name && state[setting.name]) {
          _state[setting.name] = state[setting.name]?.map((s) => {
            if (s._id === setting._id) return setting;
            else return s;
          });
        }

        return {
          ..._state,
        };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  ChangeUIMode: () => ({
    type: actionTypes.ChangeUIMode,
  }),
  get: (silent: boolean = false) => ({
    type: actionTypes.Get,
    payload: silent,
  }),
  update: (setting: ISetting) => ({
    type: actionTypes.Update,
    payload: setting,
  }),
  add: (setting: ISetting) => ({ type: actionTypes.Add, payload: setting }),
  delete: (setting: ISetting) => ({ type: actionTypes.Add, payload: setting }),
};

export function* saga() {
  yield takeLatest(actionTypes.Get, function* getSettingsSaga(params: IAction) {
    yield put({
      type: actionTypes.Get_PENDING,
      payload: { silent: params.payload },
    });

    try {
      const data: IRestApiCollection<ISetting> | IRestApiError =
        yield settingService.getAll();

      if ("error" in data) {
        throw new Error(data.error.message);
      }

      const grouped = {} as { [key in SettingsType]: Array<ISetting> };
      let { data: settings } = data;

      console.log("data", data);

      settings.map((setting) => {
        if (setting.name) {
          if (!grouped[setting.name]) grouped[setting.name] = [];

          grouped[setting.name].push(setting);
        } else throw Error("Settings error");
      });

      yield put({ type: actionTypes.Get_SUCCESS, payload: { grouped } });
    } catch (error) {
      yield put({ type: actionTypes.Get_ERROR, payload: { error } });
    }
  });
}
