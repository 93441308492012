import * as React from "react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import Box from "@mui/material/Box";
import { FormattedMessage, useIntl } from "react-intl";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useForm } from "../../hooks/useForm";
import { makeStyles } from "@material-ui/core";
import Snackbar from "../../widgets/Snackbar";
import {
  businessService,
  moduleService,
  timeTrackService,
  userService,
} from "app/services";
import { IModule } from "app/services/module.service";
import { TransitionProps } from "@mui/material/transitions";
import Controls from "../../widgets/uncontrolled";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import * as auth from "app/store/ducks/auth.duck";
import Uploader from "./uploader";
import { ITimezone } from "../../services/timeTrack.service";
import { ISetting } from "../../services/setting.service";
import ConfirmDialog from "../../partials/dialogues/confirmDialogue";

export type FormStateValues = {
  name: string;
  description: string;
  address: string;
  phone: string;
  email: string;
  timezone: string;
  languages: string[];
  default_language: string;
  vat_id: string;
  relatel_token: string;
  rn: string;
  an: string;
  currency: string;
  modules: Array<string>;
  active: number;
  file: string;
};

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

const initValues = {
  name: "",
  description: "",
  address: "",
  phone: "",
  vat_id: "",
  relatel_token: "",
  timezone: "",
  rn: "",
  an: "",
  currency: "",
  email: "",
  modules: [],
  languages: [],
  default_language: "",
  active: 1,
  file: "",
} as FormStateValues;

const useStyle = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  container: {
    width: "100%",
    padding: "0px",
    display: "flex",
    flexFlow: "column wrap",
    height: 250, // set the height limit to your liking
  },
  item: {
    width: "auto",
  },
}));

const BusinessStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const { user } = useSelector(({ auth }: RootState) => auth);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState([""]);
  const [modules, setModules] = useState<Array<IModule>>([]);
  const [timezones, setTimezones] = useState<ITimezone[]>([]);
  const [languages, setLanguages] = useState<ISetting[]>([]);
  const classes = useStyle();

  const { currencies } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const { default_languages } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    businessService
      .add(validated)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        props.setDialogue(false);
        props.onDone();
        form.values = {
          active: 0,
          description: "",
          phone: "",
          address: "",
          vat_id: "",
          rn: "",
          an: "",
          relatel_token: "",
          timezone: "",
          currency: "",
          email: "",
          file: "",
          modules: [],
          languages: [],
          default_language: "",
          name: "",
        };
        setChecked([]);

        if (user) {
          userService.getOne(user?._id).then((data) => {
            if ("error" in data) {
              throw Error(data.error.message);
            }
            dispatch(auth.actions.fulfillUser(data.data));
          });
        }
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    moduleService
      .getAll()
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: modules } = data;
        setModules(modules);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);
  const handleToggle = (value: string) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);

    if (form.values.modules?.includes(value)) {
      form.values.modules = form.values.modules?.filter((m) => m !== value);
    } else {
      form.values.modules?.push(value);
    }
  };
  const [confirm, setConfirm] = useState<boolean>(false);

  const handleClose = () => {
    if (
      form.values.phone ||
      form.values.address ||
      form.values.description ||
      form.values.vat_id ||
      form.values.rn ||
      form.values.default_language ||
      form.values.languages ||
      form.values.modules ||
      form.values.relatel_token ||
      form.values.currency ||
      form.values.file ||
      form.values.name ||
      form.values.an ||
      form.values.timezone ||
      form.values.email
    ) {
      setConfirm(true);
    } else {
      props.setDialogue(false);
      form.clear();
    }
  };

  useEffect(() => {
    timeTrackService
      .timezones()
      .then((data) => {
        if ("error" in data) throw new Error(data.error.message);

        setTimezones(data);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  const handleLanguageChange = useCallback(
    (event) => {
      if (!event.target.value) return;

      const selectedIds = event.target.value;

      // Get the selected languages directly from default_languages
      const selectedLanguages = default_languages?.filter((default_language) =>
        selectedIds.includes(default_language._id)
      );

      // Update the state with only the selected languages
      setLanguages(selectedLanguages || []);

      // Update form values
      form.values.languages = selectedIds;
    },
    [default_languages, form]
  );

  return (
    <>
      {confirm ? (
        <ConfirmDialog
          title="Close window?"
          open={confirm}
          setOpen={() => setConfirm(false)}
          onConfirm={() => {
            props.setDialogue(false);
            form.clear();
          }}
        >
          Are you sure you want to close this window?
        </ConfirmDialog>
      ) : (
        <></>
      )}
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        TransitionComponent={Transition}
        sx={{
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "rgb(245, 245, 245)",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
              <FormattedMessage
                id={"BUSINESS.ADD"}
                defaultMessage={"BUSINESS.ADD"}
              />
            </Typography>
            <Button
              autoFocus
              color="inherit"
              type={"submit"}
              form={"business_form"}
              sx={{
                backgroundColor: "#0D99FF",
                borderRadius: 2,
                color: "#ffffff",
              }}
            >
              <FormattedMessage
                id={"DIALOGUE.SAVE"}
                defaultMessage={"DIALOGUE.SAVE"}
              />
            </Button>
          </Toolbar>
        </Box>
        <DialogContent>
          <form
            id="business_form"
            onSubmit={(e) => form.handleSubmit(e, handleSave)}
          >
            <Grid container spacing={2} mb={2}>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"AVATAR"}
                        defaultMessage={"AVATAR"}
                      />
                    </Typography>
                    <Uploader
                      afterUpload={(file_id: string) =>
                        (form.values.file = file_id)
                      }
                      accept={".png,.jpg,.jpeg,"}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"business"}
                      label={""}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"ECOMMERCE.COMMON.BUSINESS"}
                        defaultMessage={"ECOMMERCE.COMMON.BUSINESS"}
                      />
                    </Typography>
                    <Controls.Input
                      onChange={(e) => {
                        if (!e.target.value) return null;

                        form.values.name = e.target.value;
                        e.preventDefault();
                      }}
                      defaultValue={form.values.name}
                      name={"business"}
                      label={""}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"business"}
                      label={""}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>

              <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"CASE.INFORMATION.EMAIL"}
                        defaultMessage={"CASE.INFORMATION.EMAIL"}
                      />
                    </Typography>
                    <Controls.Input
                      onChange={(e) => {
                        if (!e.target.value) return null;

                        form.values.email = e.target.value;
                        e.preventDefault();
                      }}
                      defaultValue={form.values.email}
                      name={"email"}
                      label={""}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"email"}
                      label={""}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"STANDARD.VATID"}
                        defaultMessage={"VAT ID"}
                      />
                    </Typography>
                    <Controls.Input
                      onChange={(e) => {
                        if (!e.target.value) return null;

                        form.values.vat_id = e.target.value;
                        e.preventDefault();
                      }}
                      defaultValue={form.values.vat_id}
                      name={"vat_id"}
                      label={""}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"email"}
                      label={""}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>

              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"USERS.PRN"}
                        defaultMessage={"USERS.PRN"}
                      />
                    </Typography>
                    <Controls.Input
                      onChange={(e) => {
                        if (!e.target.value) return null;

                        form.values.rn = e.target.value;
                        e.preventDefault();
                      }}
                      defaultValue={form.values.rn}
                      name={"rn"}
                      label={""}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"email"}
                      label={""}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>

              <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"USERS.PAN"}
                        defaultMessage={"USERS.PAN"}
                      />
                    </Typography>
                    <Controls.Input
                      onChange={(e) => {
                        if (!e.target.value) return null;

                        form.values.an = e.target.value;
                        e.preventDefault();
                      }}
                      defaultValue={form.values.an}
                      name={"an"}
                      label={""}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"email"}
                      label={""}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>

              <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"STANDARD.RELATEL_TOKEN"}
                        defaultMessage={"Relatel token"}
                      />
                    </Typography>
                    <Controls.Input
                      onChange={(e) => {
                        if (!e.target.value) return null;

                        form.values.relatel_token = e.target.value;
                        e.preventDefault();
                      }}
                      defaultValue={form.values.relatel_token}
                      name={"relatel_token"}
                      label={""}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"relatel_token"}
                      label={""}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>

              <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"STANDARD.TIMEZONE"}
                        defaultMessage={"Timezone"}
                      />
                    </Typography>
                    <Controls.Select
                      options={
                        timezones &&
                        timezones.map((timezone) => {
                          return {
                            id: timezone.id,
                            title: timezone.title ?? "",
                          };
                        })
                      }
                      formId={"timezone_pick"}
                      name={"timezone"}
                      label={""}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.timezone = event.target.value;
                      }}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"relatel_token"}
                      label={""}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>

              <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"STANDARD.LANGUAGES"}
                        defaultMessage={"Languages"}
                      />
                    </Typography>
                    <Controls.MultipleSelect
                      label={""}
                      name={"languages"}
                      formId={"languages"}
                      options={
                        default_languages
                          ? default_languages?.map(
                              (default_language: ISetting) => {
                                return {
                                  id: default_language._id.toString(),
                                  title: default_language._title,
                                };
                              }
                            )
                          : []
                      }
                      onChange={handleLanguageChange}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"relatel_token"}
                      label={""}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
                <Box className={classes.input}>
                  <Divider sx={{ mb: 1 }}>
                    <Chip
                      label={intl.formatMessage({
                        id: "STANDARD.DEFAULT_LANGUAGE",
                        defaultMessage: "Default language",
                      })}
                      sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                    />
                  </Divider>
                  <Controls.Select
                    options={
                      languages &&
                      languages.map((language) => {
                        return {
                          id: language._id,
                          title: language._title ?? "",
                        };
                      })
                    }
                    formId={"default_language"}
                    name={"default_language"}
                    label={""}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.default_language = event.target.value;
                    }}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
                <Box className={classes.input}>
                  <Divider sx={{ mb: 1 }}>
                    <Chip
                      label={intl.formatMessage({
                        id: "STANDARD.DEFAULT_CURRENCY",
                        defaultMessage: "Default currency",
                      })}
                      sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                    />
                  </Divider>
                  <Controls.Select
                    options={
                      currencies &&
                      currencies.map((currency) => {
                        return {
                          id: currency.slug,
                          title: currency.currency_title ?? "",
                        };
                      })
                    }
                    formId={"status_pick"}
                    name={"status"}
                    label={""}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.currency = event.target.value;
                    }}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Divider sx={{ mb: 1 }}>
                      <Chip
                        label={intl.formatMessage({
                          id: "STANDARD.ADDRESS",
                          defaultMessage: "Address",
                        })}
                        sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                      />
                    </Divider>
                    <Controls.Input
                      onChange={(e) => {
                        if (!e.target.value) return null;

                        form.values.address = e.target.value;
                        e.preventDefault();
                      }}
                      defaultValue={form.values.address}
                      name={"address"}
                      label={""}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"email"}
                      label={""}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Divider sx={{ mb: 1 }}>
                      <Chip
                        label={intl.formatMessage({
                          id: "STANDARD.PHONE",
                          defaultMessage: "Phone",
                        })}
                        sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                      />
                    </Divider>
                    <Controls.Input
                      onChange={(e) => {
                        if (!e.target.value) return null;

                        form.values.phone = e.target.value;
                        e.preventDefault();
                      }}
                      defaultValue={form.values.phone}
                      name={"phone"}
                      label={""}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"email"}
                      label={""}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>

              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                {loaded ? (
                  <Box className={classes.input}>
                    <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                      <FormattedMessage
                        id={"STANDARD.DESCRIPTION"}
                        defaultMessage={"STANDARD.DESCRIPTION"}
                      />
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      onChange={(e) => {
                        if (!e.target.value) return null;

                        form.values.description = e.target.value;
                        e.preventDefault();
                      }}
                      multiline={true}
                      rows={4}
                      defaultValue={form.values.description}
                      name={"description"}
                      label={""}
                    />
                  </Box>
                ) : (
                  <Skeleton width={"100%"}>
                    <Controls.Input
                      name={"description"}
                      label={""}
                      onChange={() => {}}
                    />
                  </Skeleton>
                )}
              </Grid>
            </Grid>

            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant={"body1"} sx={{ mt: 1 }}>
                <FormattedMessage
                  id={"BUSINESS.MODULES"}
                  defaultMessage={"BUSINESS.MODULES"}
                />
              </Typography>
              <List className={classes.container}>
                {modules.map((module) => {
                  const labelId = `checkbox-list-label-${module}`;
                  return (
                    <ListItem
                      key={module._id}
                      disablePadding
                      className={classes.item}
                    >
                      <ListItemButton
                        sx={{ padding: "0px 2px" }}
                        role={undefined}
                        onClick={() => {
                          handleToggle(module._id);
                        }}
                        dense
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 34,
                            "& .Mui-checked": {
                              color: "#0D99FF!important",
                            },
                          }}
                        >
                          <Checkbox
                            edge="start"
                            checked={checked.indexOf(module._id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            value={module._id}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={
                            module.title.charAt(0).toUpperCase() +
                            module.title.slice(1)
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default BusinessStore;
