import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "../../../partials/layout/DataTable";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { caseService } from "../../../services";
import { useParams } from "react-router";
import moment from "moment";
import { useTable } from "../../../hooks/subresource/useTable";
import { GridTable } from "../../../partials/layout/GridTable";
import { useIntl } from "react-intl";
import ShowCaseFeedback from "./showFeedback";
import EditIcon from "../../../partials/icons/EditIcon";

const Feedback = () => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const { data, xhrLoading, updateQuery } = useTable<any>({
    fetch: caseService.getFeedbacks,
    param: id,
    query: [
      {
        name: "per_page",
        value: 5,
      },
    ],
  });

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);

  const columns: IColumns[] = [
    {
      field: "_id",
      headerName: "ID",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
    },
    {
      field: "draft",
      headerName: intl.formatMessage({
        id: "CASE.FEEDBACK.DRAFT",
        defaultMessage: "Draft",
      }),
      valueGetter: (params) => {
        return params.row?.draft;
      },
      minWidth: 300,
    },
    {
      field: "comments",
      headerName: intl.formatMessage({ id: "CASE.FEEDBACK.COMMENT" }),
      valueGetter: (params) => {
        return params.row?.comment;
      },
      minWidth: 300,
    },
    {
      field: "smile",
      headerName: intl.formatMessage({ id: "CASE.FEEDBACK.SMILEFEEDBACK" }),
      align: "left",
      minWidth: 200,
      renderCell: (params) => {
        let path;
        let emoji = +params.row?.emoji;

        if (emoji === -2) {
          path = "/media/smiles/angry.svg";
        } else if (emoji === -1) {
          path = "/media/smiles/sad.svg";
        } else if (emoji === 0) {
          path = "/media/smiles/neutral.svg";
        } else if (emoji === 1) {
          path = "/media/smiles/good.svg";
        } else if (emoji === 2) {
          path = "/media/smiles/excellent.svg";
        } else {
          path = "/media/logos/logo-2.png";
        }

        return (
          <Box
            component="img"
            sx={{
              height: 50,
              width: 50,
              textAlign: "left",
            }}
            alt={params.row?.comment}
            src={path}
          />
        );
      },
    },
    {
      field: "_created_at",
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      valueGetter: (params) => {
        return moment
          .unix(params.row?._created_at)
          .local()
          .format("DD/MM/YYYY");
      },
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => show(params.row?._id)}
              data-cy-class={"case_feedback_show"}
              data-cy-case-id={id}
              data-cy-feedback-id={params.row?._id}
            >
              <EditIcon />
            </IconButton>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const [feedbackId, setFeedbackId] = useState<string | null>(null);

  const show = (_id: string) => {
    setFeedbackId(_id);
  };

  return (
    <>
      {feedbackId ? (
        <ShowCaseFeedback
          _case={id}
          _id={feedbackId}
          open={Boolean(feedbackId)}
          setDialogue={() => setFeedbackId(null)}
        />
      ) : (
        <></>
      )}
      <GridTable
        loading={xhrLoading}
        skeleton={!init}
        pageSize={data?.per_page}
        count={data?.total}
        columns={columns}
        rows={data?.data ?? []}
        tableRef={tableRef}
        query={(queries) => updateQuery(queries)}
        search={(queries) => updateQuery(queries)}
      />
    </>
  );
};

export default Feedback;
