import React, { FC, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useIntl } from "react-intl";
import { useForm } from "app/hooks/useForm";
import { useParams } from "react-router";
import { aiservice, caseService, userService } from "../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Snackbar from "../../widgets/Snackbar";

type IFormProps = {
  type: string;
  focus_type?: string;
  focus_area?: string;
  time_track: string;
  open: boolean;
  save?: Function;
  _id?: string;
  onDone: Function;
};

type FormStateValues = {
  time_track: string;
  user: string;
  emoji: number;
  comment: string;
  draft: string;
};

const initValues = {
  emoji: 0,
  comment: "",
  draft: "",
} as FormStateValues;

const FeedbackDialogue: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const form = useForm<FormStateValues>(initValues);
  const [close, setClose] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(props.open);
  const { user } = useSelector(({ auth }: RootState) => auth);
  const [comment, setComment] = useState<string>("");
  const [original, setOriginal] = useState<string>("");

  const feedbackEmojis = [
    {
      path: "/media/smiles/angry.svg",
      index: -2,
    },
    {
      path: "/media/smiles/sad.svg",
      index: -1,
    },
    {
      path: "/media/smiles/neutral.svg",
      index: 0,
    },
    {
      path: "/media/smiles/good.svg",
      index: 1,
    },
    {
      path: "/media/smiles/excellent.svg",
      index: 2,
    },
  ];

  useEffect(() => {
    if (close) {
      setOpen(false);
    } else {
      setOpen(props.open);
    }
  });

  const handleClose = () => {
    setClose(true);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    validated["type"] = props.type;
    if (props.type === "case") {
      caseService
        .postTimeTrackFeedback(id, validated)
        .then((data) => {
          if ("error" in data) {
            throw Error(data.error.message);
          }

          Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));
          handleClose();
          form.clear();
          props.onDone();
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    } else {
      userService
        .postTimeTrackFeedback(id, validated)
        .then((data) => {
          if ("error" in data) {
            throw Error(data.error.message);
          }

          Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));
          handleClose();
          form.clear();
          props.onDone();
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  };

  const handleGrammar = () => {
    if (comment.length < 1) {
      Snackbar.error(
        intl.formatMessage({
          id: "SNACKBAR.TEXTERROR",
          defaultMessage: "Please enter a text",
        })
      );
      return;
    }

    aiservice.grammar({ text: comment }).then((data) => {
      setEditable(true);
      if (data.corrected_text) setOriginal(data.corrected_text);
    });
  };

  const handleHighLevelGrammar = () => {
    if (comment.length < 1) {
      Snackbar.error(
        intl.formatMessage({
          id: "SNACKBAR.TEXTERROR",
          defaultMessage: "Please enter a text",
        })
      );
      return;
    }

    aiservice
      .highlevelgrammar({
        text: comment,
        focus_type: props.focus_type ?? "",
        focus_area: props.focus_area ?? "",
      })
      .then((data) => {
        setEditable(true);

        if (data.corrected_text) setOriginal(data.corrected_text);
      });
  };

  const languageCodes: any = {
    en: "en-US",
    ar: "ar-AR",
    zh: "zh-CN",
    cs: "cs-CZ",
    da: "da-DK",
    nl: "nl-NL",
    fi: "fi-FI",
    fr: "fr-FR",
    de: "de-DE",
    el: "el-GR",
    hi: "hi-IN",
    hu: "hu-HU",
    it: "it-IT",
    ja: "ja-JP",
    ko: "ko-KR",
    pl: "pl-PL",
    pt: "pt-BR",
    ru: "ru-RU",
    es: "es-ES",
    sv: "sv-SE",
    tr: "tr-TR",
    vi: "vi-VN",
    az: "az-AZ",
  };

  const current = languageCodes[intl.locale] || languageCodes.en;
  const [isNoteListening, setIsNoteListening] = useState(false);
  const lastProcessedTranscriptShow = useRef("");
  const lastNoteProcessedTranscriptShow = useRef("");
  const recognitionTodo = useRef<SpeechRecognition | null>(null);
  const recognitionNote = useRef<SpeechRecognition | null>(null);
  const [noteTranscript, setNoteTranscript] = useState("");
  const noteDescRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (
      !("SpeechRecognition" in window || "webkitSpeechRecognition" in window)
    ) {
      Snackbar.error("Your browser does not support speech recognition.");
      return;
    }
    // Setup recognition for Note
    recognitionNote.current = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition)();
    recognitionNote.current.continuous = true;
    recognitionNote.current.interimResults = false;
    recognitionNote.current.lang = current;

    recognitionNote.current.onresult = (event) => {
      let combinedTranscript = "";
      for (let i = 0; i < event.results.length; i++) {
        combinedTranscript += event.results[i][0].transcript;
      }
      setNoteTranscript(combinedTranscript);
    };

    recognitionNote.current.onerror = (event) => {
      Snackbar.error(`Note Description Recognition Error: ${event.error}`);
    };

    // Cleanup on unmount
    return () => {
      if (recognitionTodo.current) {
        recognitionTodo.current.stop();
        recognitionTodo.current = null;
      }
      if (recognitionNote.current) {
        recognitionNote.current.stop();
        recognitionNote.current = null;
      }
    };
  }, [current]);

  const handleStart = () => {
    if (recognitionNote.current) {
      lastNoteProcessedTranscriptShow.current = "";
      setNoteTranscript("");
      setIsNoteListening(true);
      recognitionNote.current.start();
    }
  };

  const handleStop = () => {
    if (recognitionNote.current) {
      recognitionNote.current.stop();
      setIsNoteListening(false);
    }
  };

  const handleReset = () => {
    lastProcessedTranscriptShow.current = "";
    lastNoteProcessedTranscriptShow.current = "";
    setComment("");
    setOriginal("");
    setEditable(false);
    if (noteDescRef.current) {
      noteDescRef.current.value = "";
    }
  };

  useEffect(() => {
    if (noteTranscript.length > 0) {
      const newText = noteTranscript
        .replace(lastNoteProcessedTranscriptShow.current, "")
        .trim();
      if (newText) {
        if (noteDescRef.current) {
          noteDescRef.current.value =
            (noteDescRef.current.value ?? " ") + " " + newText;
          setComment(noteDescRef.current.value);
        }

        lastNoteProcessedTranscriptShow.current = noteTranscript;
      }
    }
  }, [noteTranscript]);

  return (
    <form
      id={"feedback_form"}
      onSubmit={(e) => form.handleSubmit(e, handleSave)}
    >
      <Dialog
        onClose={handleClose}
        open={true}
        fullScreen={false}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {intl.formatMessage({
              id: "FEEDBACK.TITLE",
              defaultMessage: "Rate Session",
            })}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            {feedbackEmojis.map((emoji) => {
              return (
                <Button
                  onClick={() => {
                    form.values.emoji = emoji.index;
                  }}
                >
                  <img
                    style={{
                      height: 45,
                      marginTop: "20px",
                      marginBottom: "30px",
                      width: 45,
                      textAlign: "center",
                    }}
                    src={emoji.path}
                    alt={""}
                  />
                </Button>
              );
            })}
          </Box>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={editable ? 6 : 12}
              lg={editable ? 6 : 12}
              xl={editable ? 6 : 12}
            >
              <Button
                onClick={() => {
                  isNoteListening ? handleStop() : handleStart();
                }}
                style={{
                  padding: "10px",
                  borderRadius: "50%",
                  border: "none",
                  cursor: "pointer",
                }}
                sx={{ marginRight: "10px" }}
                variant="outlined"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill={isNoteListening ? "red" : "green"}
                    d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm-2,7c0-1.105.895-2,2-2s2,.895,2,2v5c0,1.105-.895,2-2,2s-2-.895-2-2v-5Zm3,10.916v1.084c0,.552-.447,1-1,1s-1-.448-1-1v-1.084c-2.834-.477-5-2.948-5-5.916,0-.552.447-1,1-1s1,.448,1,1c0,2.206,1.794,4,4,4s4-1.794,4-4c0-.552.447-1,1-1s1,.448,1,1c0,2.968-2.166,5.439-5,5.916Z"
                  />
                </svg>

                <span
                  style={{
                    color: isNoteListening ? "red" : "green",
                    marginLeft: "10px",
                  }}
                >
                  {isNoteListening
                    ? intl.formatMessage({
                        id: "STANDARD.STOP_LISTENING",
                        defaultMessage: "Stop Listening",
                      })
                    : intl.formatMessage({
                        id: "STANDARD.START_LISTENING",
                        defaultMessage: "Start Listening",
                      })}
                </span>
              </Button>
              <Button
                onClick={() => {
                  handleReset();
                }}
                style={{
                  padding: "5px",
                  border: "none",
                  background: "red",
                  color: "#fff",
                  cursor: "pointer",
                }}
                sx={{ marginRight: "10px" }}
                variant="outlined"
              >
                {intl.formatMessage({
                  id: "STANDARD.CLEAR",
                  defaultMessage: "Clear",
                })}
              </Button>
              <TextField
                id="standard-basic"
                onChange={(e) => {
                  if (!editable) {
                    setComment(e.target.value);
                  }
                }}
                ref={noteDescRef}
                sx={{ width: "100%" }}
                value={comment}
                disabled={editable}
                multiline
                rows={4}
                label={""}
                variant="standard"
              />
            </Grid>
            {editable && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box
                  style={{
                    padding: "10px",
                    borderRadius: "50%",
                    border: "none",
                    cursor: "pointer",
                    height: 44,
                  }}
                  sx={{ marginRight: "10px" }}
                ></Box>
                <TextField
                  id="standard-basic"
                  onChange={(e) => {
                    setOriginal(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                  value={original}
                  multiline
                  rows={4}
                  label={""}
                  variant="standard"
                />
              </Grid>
            )}
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <>
              <Button
                onClick={() => {
                  handleGrammar();
                }}
                sx={{ marginRight: "10px" }}
                variant="contained"
              >
                {intl.formatMessage({
                  id: "STANDARD.GRAMMAR_CORRECTION",
                  defaultMessage: "Grammar correction",
                })}
              </Button>
              <Button
                onClick={() => {
                  handleHighLevelGrammar();
                }}
                sx={{ marginRight: "10px" }}
                variant="contained"
              >
                {intl.formatMessage({
                  id: "STANDARD.HIGHER_LEVEL",
                  defaultMessage: "Higher level correction",
                })}
              </Button>
              <Button
                onClick={() => {
                  handleClose();
                }}
                sx={{ marginRight: "10px" }}
                variant="outlined"
              >
                {intl.formatMessage({
                  id: "STANDARD.CANCEL",
                  defaultMessage: "Cancel",
                })}
              </Button>
              <Button
                type={"submit"}
                form={"feedback_form"}
                onClick={() => {
                  if (user) {
                    form.values.user = user._id;
                    form.values.time_track = props.time_track;
                    form.values.comment = original;
                    form.values.draft = comment;
                  }
                }}
                variant="contained"
              >
                {intl.formatMessage({
                  id: "STANDARD.SUBMIT",
                  defaultMessage: "Submit",
                })}
              </Button>
            </>
          </Box>
        </DialogContent>
      </Dialog>
    </form>
  );
};

export default FeedbackDialogue;
