import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Snackbar from "app/widgets/Snackbar";
import { useIntl } from "react-intl";
import moment from "moment";
import { useForm } from "app/hooks/useForm";
import { caseService, userService } from "app/services";
import {
  ICase,
  ICaseTimeTrackAnswer,
  ICaseTimeTrackQuestions,
} from "app/services/case.service";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { useParams } from "react-router";
import { IUser } from "../../interfaces";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import FeedbackDialogue from "./FeedbackDialogue";

type IFormProps = {
  open: boolean;
  type: string;
  focus_type?: string;
  focus_area?: string;
  save?: Function;
  time_track: string;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
};

type FormStateValues = {
  data: Array<ICaseTimeTrackAnswer>;
};

const initValues = {
  data: [],
};

const SurveyDialogue: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [questions, setQuestions] = useState<Array<ICaseTimeTrackQuestions>>(
    []
  );
  const [lengthError, setLengthError] = useState<boolean>(false);
  const [activeQuestion, setActiveQuestionIndex] = useState<number>(0);
  const [users, setUsers] = useState<Array<IUser>>();
  const [answersByQuestions, setAnswersByQuestions] = useState<Array<string>>(
    []
  );
  const [cases, setCases] = useState<Array<ICase>>();
  const [answer, setAnswer] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [close, setClose] = useState<boolean>(false);
  const [nextDialogue, setNextDialogue] = useState<boolean>(false);
  const [isBack, setIsBack] = useState<boolean>(false);

  useEffect(() => {
    if (props.time_track && props.type === "case") {
      caseService
        .getTimeTrackSurveys(id, [
          {
            name: "pagination",
            value: "0",
          },
        ])
        .then((data) => {
          if ("error" in data) {
            throw Error(data.error.message);
          }
          let { data: questions } = data;
          setQuestions(questions);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });

      userService
        .getAll([
          {
            name: "pagination",
            value: "0",
          },
        ])
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          const { data: users } = data;
          setUsers(users);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });

      caseService
        .getAll([
          {
            name: "pagination",
            value: "0",
          },
        ])
        .then((data) => {
          if ("error" in data) {
            throw new Error(data.error.message);
          }

          const { data: cases } = data;
          setCases(cases);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
    }
  }, [props.time_track]);

  useEffect(() => {
    if (!questions.length) {
      setNextDialogue(true);
    }
  }, [questions]);

  useEffect(() => {
    if (props.type === "user") {
      setNextDialogue(true);
    }
  }, [props.type]);

  // questions dialog next/back buttons
  const handleNext = () => {
    if (activeQuestion + 1 < questions.length) {
      setActiveQuestionIndex(activeQuestion + 1);
    }
  };

  const handleBack = () => {
    if (activeQuestion - 1 > -1) {
      setActiveQuestionIndex(activeQuestion - 1);
    }
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    caseService
      .updateTimeTrackSurveys(id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(
            intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" })
          );
        }

        Snackbar.info(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
      })
      .catch((e) => {
        Snackbar.error(e.message);
      })
      .finally(() => {
        setQuestions([]);
      });
  };

  const answerByQuestion = (e: string) => {
    setAnswersByQuestions([...answersByQuestions].concat(e));
  };

  useEffect(() => {
    if (close) {
      setOpen(false);
    }

    if (questions.length === 0 && close && !props.open) {
      setTimeout(() => {
        setNextDialogue(true);
      }, 1000);
    } else if (questions.length !== 0 && !close) {
      setTimeout(() => {
        setOpen(props.open);
      }, 500);
    }
  }, [close]);

  const handleClose = () => {
    setClose(false);
  };

  return (
    <>
      <form
        id={"questions_form"}
        onSubmit={(e) => {
          form.handleSubmit(e, handleSave);
          e.preventDefault();
        }}
      >
        <Dialog
          onClose={() => setQuestions([])}
          open={Boolean(questions.length)}
          fullScreen={false}
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogTitle>
            <Grid container>
              <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                <Box>Trading plan - pop up questions</Box>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={() => {
                      handleClose();
                      setNextDialogue(true);
                    }}
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
            <Box
              style={{
                backgroundColor: "#5D78FB",
                width: "40%",
                color: "#ffffff",
                borderRadius: "5px",
                textAlign: "center",
              }}
            >
              Questions {activeQuestion + 1}/{questions.length}
            </Box>
          </DialogTitle>
          <DialogContent>
            {loaded ? (
              <Box>
                {questions[activeQuestion] &&
                  questions[activeQuestion].title["da"]}
              </Box>
            ) : (
              <Skeleton sx={{ width: "100%", height: 80 }} />
            )}
            <Accordion sx={{ marginTop: "10px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Case</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Box sx={{ display: "flex" }}>
                    <Chip
                      sx={{ mr: "10px" }}
                      label={intl.formatMessage({
                        id: "CASE.INFORMATION.EMPLOYEE",
                      })}
                      color="primary"
                      variant="filled"
                    />

                    {questions[activeQuestion] &&
                      questions[activeQuestion].users &&
                      users &&
                      questions[activeQuestion].users.map((u) => {
                        return users.map((user) => {
                          if (user._id === u) {
                            if (
                              user.role &&
                              user.role.title["en"] === "Employee"
                            ) {
                              if (loaded) {
                                return (
                                  <Chip
                                    sx={{ ml: 1 }}
                                    label={`${user.firstname}`}
                                    color="primary"
                                    variant="outlined"
                                  />
                                );
                              } else {
                                return (
                                  <Skeleton
                                    sx={{ width: 70, ml: 1, height: 32 }}
                                  />
                                );
                              }
                            }
                          }
                        });
                      })}
                  </Box>
                  <Box sx={{ mt: "5px", display: "flex" }}>
                    <Chip
                      label={intl.formatMessage({
                        id: "CASE.INFORMATION.CITIZEN",
                      })}
                      color="primary"
                      variant="filled"
                    />
                    {questions[activeQuestion] &&
                      questions[activeQuestion].users &&
                      users &&
                      questions[activeQuestion].users.map((u) => {
                        return users.map((user) => {
                          if (
                            user._id === u &&
                            user.role &&
                            user.role.title["en"] === "Citizen"
                          ) {
                            if (loaded) {
                              return (
                                <Chip
                                  sx={{ ml: 1 }}
                                  label={`${user.firstname}`}
                                  color="primary"
                                  variant="outlined"
                                />
                              );
                            } else {
                              return (
                                <Skeleton
                                  sx={{ width: 70, ml: 1, height: 32 }}
                                />
                              );
                            }
                          }
                        });
                      })}
                  </Box>
                  <Box sx={{ mt: "5px", display: "flex" }}>
                    <Chip
                      label={intl.formatMessage({ id: "STANDARD.DATE" })}
                      color="primary"
                      variant="filled"
                    />

                    {cases?.map((c) => {
                      if (
                        questions[activeQuestion] &&
                        questions[activeQuestion].survey.case &&
                        c._id === questions[activeQuestion].survey.case
                      ) {
                        if (loaded) {
                          return (
                            <Chip
                              sx={{ ml: 1 }}
                              label={`${moment
                                .unix(+c._created_at)
                                .local()
                                .format("DD/MM/YYYY")}`}
                              color="primary"
                              variant="outlined"
                            />
                          );
                        } else {
                          return (
                            <Skeleton sx={{ width: 70, ml: 1, height: 32 }} />
                          );
                        }
                      }
                    })}
                  </Box>
                  <Box sx={{ mt: "5px", display: "flex" }}>
                    <Chip
                      label={intl.formatMessage({
                        id: "CASE.TIMETRACK.STARTLOCATION",
                      })}
                      color="primary"
                      variant="filled"
                    />
                    {loaded ? (
                      <Chip
                        sx={{ ml: 1 }}
                        label={`${
                          questions[activeQuestion] &&
                          questions[activeQuestion].time_track.start_location &&
                          questions[activeQuestion].time_track.start_location
                        }`}
                        color="primary"
                        variant="outlined"
                      />
                    ) : (
                      <Skeleton sx={{ width: 130, ml: 1, height: 32 }} />
                    )}
                  </Box>
                  <Box sx={{ mt: "5px", display: "flex" }}>
                    <Chip
                      label={intl.formatMessage({
                        id: "CASE.TIMETRACK.ENDLOCATION",
                      })}
                      color="primary"
                      variant="filled"
                    />
                    {loaded ? (
                      <Chip
                        sx={{ ml: 1 }}
                        label={`${
                          questions[activeQuestion] &&
                          questions[activeQuestion].time_track.end_location &&
                          questions[activeQuestion].time_track.end_location
                        }`}
                        color="primary"
                        variant="outlined"
                      />
                    ) : (
                      <Skeleton sx={{ width: 130, ml: 1, height: 32 }} />
                    )}
                  </Box>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Trading plans</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Tooltip
                    title={`${
                      questions[activeQuestion] &&
                      questions[activeQuestion] &&
                      questions[activeQuestion].plan.action_plan &&
                      questions[activeQuestion] &&
                      questions[activeQuestion].plan.action_plan
                    }`}
                  >
                    <Box sx={{ mt: 1, display: "flex" }}>
                      <Chip
                        label={`${intl.formatMessage({
                          id: "CASE.PLAN.ACTIONPLAN",
                        })}:`}
                        color="primary"
                        variant="filled"
                      />
                      {loaded ? (
                        <Chip
                          sx={{ ml: 1, maxWidth: "250px" }}
                          label={`${
                            questions[activeQuestion] &&
                            questions[activeQuestion] &&
                            questions[activeQuestion].plan.action_plan &&
                            questions[activeQuestion] &&
                            questions[activeQuestion].plan.action_plan
                          }`}
                          color="primary"
                          variant="outlined"
                        />
                      ) : (
                        <Skeleton sx={{ width: 200, ml: 1 }} />
                      )}
                    </Box>
                  </Tooltip>
                  <Box sx={{ mt: 1, display: "flex" }}>
                    <Chip
                      label={`${intl.formatMessage({ id: "CASE.PLAN.GOAL" })}:`}
                      color="primary"
                      variant="filled"
                    />
                    {loaded ? (
                      <Chip
                        sx={{ ml: 1 }}
                        label={`${
                          questions[activeQuestion] &&
                          questions[activeQuestion] &&
                          questions[activeQuestion].goal.title[intl.locale] &&
                          questions[activeQuestion] &&
                          questions[activeQuestion].goal.title[intl.locale]
                        }`}
                        color="primary"
                        variant="outlined"
                      />
                    ) : (
                      <Skeleton sx={{ width: 200, ml: 1 }} />
                    )}
                  </Box>
                </Typography>
              </AccordionDetails>
            </Accordion>
            {lengthError ? (
              <Box sx={{ color: "red", marginTop: "10px" }}>
                Answer is too short
              </Box>
            ) : null}
            {questions.map((question, index) => {
              if (index === activeQuestion) {
                return (
                  <React.Fragment>
                    <TextField
                      id="standard-basic"
                      onChange={(e) => {
                        setAnswer(e.target.value);
                      }}
                      sx={{ width: "100%", marginTop: "10px" }}
                      multiline
                      rows={4}
                      defaultValue={
                        answersByQuestions && answersByQuestions[activeQuestion]
                      }
                      label="Leave comment"
                      variant="standard"
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <>
                        {activeQuestion === 0 ? null : (
                          <Button
                            onClick={() => {
                              handleBack();
                              setIsBack(true);
                              //setAnswer(answer)
                            }}
                            sx={{ marginRight: "10px" }}
                            variant="contained"
                          >
                            <ArrowLeftIcon /> Back
                          </Button>
                        )}
                        {activeQuestion < questions.length - 1 ? (
                          <Button
                            onClick={() => {
                              // if (answersByQuestions[activeQuestion] !== answer) {
                              //     setAnswer(answer)
                              //     //answersByQuestions[activeQuestion] = answer
                              // }
                              if (
                                answer.trim().length < 4 &&
                                answer === "" &&
                                isBack
                              ) {
                                setLengthError(true);
                              } else {
                                form.values.data.push({
                                  plan: question.plan._id,
                                  answered_text: answer,
                                  survey: question.survey._id,
                                  question: question._id,
                                });
                                answerByQuestion(answer);
                                setAnswer("");
                                handleNext();
                                setLengthError(false);
                              }
                            }}
                            sx={{ marginRight: "10px" }}
                            variant="contained"
                          >
                            Next <ArrowRightIcon />
                          </Button>
                        ) : null}
                        {activeQuestion === questions.length - 1 ? (
                          <Button
                            onClick={() => {
                              if (answer.trim().length < 4 && answer === "") {
                                setLengthError(true);
                              }

                              if (
                                form.values.data[activeQuestion - 1]
                                  .question !== question._id
                              ) {
                                form.values.data.push({
                                  plan: question.plan._id,
                                  answered_text: answer,
                                  survey: question.survey._id,
                                  question: question._id,
                                });
                                answerByQuestion(answer);
                                setAnswer("");
                              }

                              // handleClose();
                              setNextDialogue(true);
                            }}
                            type={"submit"}
                            form={"questions_form"}
                            variant="contained"
                          >
                            Finish
                          </Button>
                        ) : null}
                      </>
                    </Box>
                  </React.Fragment>
                );
              }
            })}
          </DialogContent>
        </Dialog>
      </form>

      {nextDialogue && (
        <FeedbackDialogue
          time_track={props.time_track}
          open={nextDialogue}
          type={props.type}
          focus_type={props.focus_type}
          focus_area={props.focus_area}
          onDone={() => setNextDialogue(false)}
        />
      )}
    </>
  );
};

export default SurveyDialogue;
